import styles from "./CaptureDriver.module.scss";
import { useState } from "react";
import VehiclePhotoPlaceholder from "../../../assets/VehiclePhotoPlaceholder.svg";

import { ReactComponent as CaptureBigIcon } from "../../../assets/CaptureBig.svg";
import DriverDeviceTable, { DriverDeviceEntry } from "./DriverVehiclesTable";
import { useQueryDeviceFilteredByDeviceIdOrVehicle } from "../../../hooks/useQueryDevices";
import { devicesToDriverVehicles } from "./driver-vehicles-table.util";
import { useVehiclesQueryParamsContext } from "../../../context/QueryParamsContext/VehiclesQueryParamsContextProvider";
import Pagination from "../../Table/Pagination";
import { SearchBox } from "../../form/SearchBox/SearchBox";
import { addBase64Prefix } from "../../../util/shared.util";
import { useTspFleetContext } from "../../../context/TspFleetContext/TspFleetContext";
import { Fleet } from "../../../api/data-contracts";
import useQueryOwnFleet from "../../../hooks/useQueryOwnFleet";
import VehicleIcon from "../../../icons/VehicleIcon";
import PhotoWithOverlay from "../../VehiclesTable/PhotoOverlay";

interface AddDriverModalProps {
  photo?: string;
  onDeviceSelected: (deviceId?: string) => void;
}

export type AddDriverFormInputs = {
  driverIdName: string;
  photoUrl?: string;
};

function CaptureDriver({ photo, onDeviceSelected }: AddDriverModalProps) {
  const [deviceFilter, setDeviceFilter] = useState<string>();
  const [selectedDevice, setSelectedDevice] = useState<DriverDeviceEntry | undefined>();

  const { selected } = useTspFleetContext();
  const { data: ownFleet } = useQueryOwnFleet();

  const selectedFleet = (selected as Fleet) || ownFleet;
  const {
    data: deviceData,
    isError: isErrorDevices,
    isLoading: isLoadingDevices,
    isFetching: isFetchingDevices,
  } = useQueryDeviceFilteredByDeviceIdOrVehicle(
    deviceFilter,
    undefined,
    selectedFleet && { tspId: selectedFleet.tspId!, fleetId: selectedFleet.id },
    devicesToDriverVehicles
  );
  const { queryParams, setPageIndex } = useVehiclesQueryParamsContext();

  const onRowClicked = (selected?: DriverDeviceEntry) => {
    setDeviceFilter("");
    onDeviceSelected(selected?.deviceId);
    setSelectedDevice(selected);
  };
  const photoArea = (
    <div className={`${styles["photo-container"]} flex-fill justify-content-center d-flex`}>
      {!photo && <CaptureBigIcon className={styles["capture-big-icon"]} />}
      {photo && <img src={addBase64Prefix(photo)} className={styles["photo"]} alt="driver" />}
    </div>
  );

  return (
    <div id="captureDriver" className="h-100 d-flex flex-column h-100">
      <SearchBox
        search={deviceFilter}
        defaultPlaceHolderMessage="Type Device Id or Vehicle License plate"
        placeHolderId="SEARCH_DEVICE_BOX"
        onChange={(e) => setDeviceFilter(e.target.value)}
      />
      <>
        {selectedDevice && (
          <div className="d-flex align-items-center gap-4">
            <div className="d-flex align-items-center gap-4">
              <VehicleIcon name={selectedDevice.vehicleType} size="large" />
            </div>
            <div className="d-flex align-items-center gap-4">
              <PhotoWithOverlay src={selectedDevice.photo || VehiclePhotoPlaceholder} />
            </div>
            <div>{selectedDevice.deviceId}</div>
            <div>{selectedDevice.licensePlate}</div>
          </div>
        )}
        {deviceData?.data && (
          <>
            <DriverDeviceTable
              data={deviceData?.data}
              isError={isErrorDevices}
              isLoading={isLoadingDevices}
              isFetching={isFetchingDevices}
              onClickRow={(device) => {
                onRowClicked(device);
              }}
              alertDriverLimit={false}
            />
            <Pagination
              className="mt-auto"
              debouncedPageIndex={queryParams.paging.pageIndex}
              onChangePageIndex={setPageIndex}
              pageCount={deviceData?.pageCount}
              allDisabled={isFetchingDevices}
              totalEntries={deviceData?.totalCount}
            />
          </>
        )}
      </>
      {!deviceData?.data && photoArea}
    </div>
  );
}

export default CaptureDriver;
