import { QueryParamsContextProvider, useQueryParamsContext } from "./QueryParamsContext";
import { GetRulesParams,  RulesSortFilterParamName } from "../../services/rule.service";
import { PropsWithChildren } from "react";

import { Constants } from "../../constants";
import { RULES_CONTEXT } from "./queryParamContextObjects";

export function RulesQueryParamsContextProvider({ children }: PropsWithChildren) {
  const initialParams: GetRulesParams = {
    filters: [],
    sorts:[{ param: "createdDateTime", descending: true }],
    paging: { pageIndex: 1, pageSize: Constants.DEFAULT_PAGESIZE },
  };

  return <QueryParamsContextProvider context={RULES_CONTEXT} initialParams={initialParams}>{children}</QueryParamsContextProvider>;
}

export const useRulesQueryParamsContext = () =>
  useQueryParamsContext<RulesSortFilterParamName, RulesSortFilterParamName>(RULES_CONTEXT);
