import { FormattedMessage } from "react-intl";
import styles from "./EventDetailModal.module.scss";
import { Col, Row } from "react-bootstrap";
import { ReactNode } from "react";
import { OverlayItem, OverlayItemCustom } from "../../Overlay/OverlayItem";

interface EventDetailItemProps {
    name: string;
    value: ReactNode | number | string | undefined | null;
}

function EventDetailItem({ name, value }: EventDetailItemProps) {

    return (
        <>
            <Row className="gap-1 flex-nowrap align-items-center">
                <Col xl={5} className={styles["itemHeader"]}>
                    <FormattedMessage id={name} defaultMessage={name} />
                </Col>
                {
                    (name ==='ADDRESS' || name === 'DRIVER_NAME')? 
                    <Col xl={7} className={styles["itemValue"]}>
                        <OverlayItemCustom position="bottom" value={value} className={styles["eventDetailValueStyle"]} />
                    </Col> 
                    :  
                    <Col xl={7} className={styles["itemValue"]}>
                        <OverlayItem position="bottom" value={value} className={styles["eventDetailValueStyle"]} />
                    </Col>
                }
            </Row>
            <div className={`${styles["seperator"]} mt-2`}></div>
        </>
    )
}

export default EventDetailItem;