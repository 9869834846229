import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Color, RouteConstants } from "../../constants";
import styles from "./ManagementNavbar.module.css";

import { ReactComponent as UsersIcon } from "../../assets/icons/Users.svg";
import { ReactComponent as VehicleIcon } from "../../assets/icons/Vehicle.svg";
import { ReactComponent as DriverIcon } from "../../assets/icons/Driver.svg";
import { ReactComponent as DevicesIcon } from "../../assets/icons/Device.svg";
import { ReactComponent as SettingsIcon } from "../../assets/icons/Settings.svg";
import { ReactComponent as ConfigurationIcon } from "../../assets/icons/Configuration.svg";
import { ReactComponent as BillingIcon } from "../../assets/icons/Billing.svg";
import { ReactComponent as NotifiationIcon } from "../../assets/icons/Notification.svg";

import NavbarButton from "../NavbarButton/NavbarButton";
import { useAuthContext } from "../../context/AuthContext";
import { Role } from "../../api/data-contracts";

function ManagementNavbar() {
  const { loggedUser } = useAuthContext();
  const isTsp = loggedUser?.role === Role.SpManager || loggedUser?.role === Role.SpUser;
  const isFleet = loggedUser?.role === Role.FleetManager || loggedUser?.role === Role.FleetUser;


  return (
    <Navbar>
      <Nav className="justify-content-center w-100">
        <div className={`d-flex flex-row`}>
          <NavbarButton
            path={RouteConstants.NOTIFICATIONS}
            buttonClass={styles["left-button"]}
            text="Rules"
            iconRenderer={(isActive) => <NotifiationIcon className={styles["notification-icon"]} fill={isActive ? Color.WHITE : Color.DARK_GRAY_1} />}
          />
          <NavbarButton
            path={RouteConstants.USERS}
            buttonClass={styles["center-button"]}
            text="Users"
            iconRenderer={(isActive) => <UsersIcon fill={isActive ? Color.WHITE : Color.DARK_GRAY_1} />}
          />
          <NavbarButton
            path={RouteConstants.DEVICES}
            buttonClass={styles["center-button"]}
            text="Devices"
            iconRenderer={(isActive) => <DevicesIcon fill={isActive ? Color.WHITE : Color.DARK_GRAY_1} />}
          />
          {!isTsp && (
            <NavbarButton
              path={RouteConstants.DRIVERS}
              buttonClass={styles["center-button"]}
              text="Drivers"
              iconRenderer={(isActive) => <DriverIcon fill={isActive ? Color.WHITE : Color.DARK_GRAY_1} />}
            />
          )}
          <NavbarButton
            path={RouteConstants.VEHICLES}
            buttonClass={styles["center-button"]}
            text="Vehicles"
            iconRenderer={(isActive) => <VehicleIcon fill={isActive ? Color.WHITE : Color.DARK_GRAY_1} />}
          />
          <NavbarButton
            path={RouteConstants.SETTINGS}
            buttonClass={styles["center-button"]}
            text="Settings"
            iconRenderer={(isActive) => <SettingsIcon fill={isActive ? Color.WHITE : Color.DARK_GRAY_1} />}
          />
          {!isFleet && (
            <NavbarButton
              path={RouteConstants.CONFIGURATION}
              buttonClass={styles["center-button"]}
              text="Configuration"
              iconRenderer={(isActive) => <ConfigurationIcon fill={isActive ? Color.WHITE : Color.DARK_GRAY_1} />}
            />)
          }
          <NavbarButton
            path={RouteConstants.BILLING}
            buttonClass={styles["right-button"]}
            text="Billing"
            iconRenderer={(isActive) => <BillingIcon fill={isActive ? Color.WHITE : Color.DARK_GRAY_1} />}
          />
        </div>
      </Nav>
    </Navbar>
  );
}

export default ManagementNavbar;
