import { useRef } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { ReactComponent as CheckVIcon } from "../../assets/icons/CheckV.svg";
import { ReactComponent as TspIcon } from "../../assets/icons/TSP.svg";
import { Color } from "../../constants";
import { LangNamesType, useIntlContext } from "../../context/IntlContext";
import { generatePassword, isPasswordValid } from "../../util/password.util";
import { toBase64 } from "../../util/shared.util";
import ModalMainButton from "../Buttons/ModalMainButton/ModalMainButton";
import ModalSecondaryButton from "../Buttons/ModalSecondaryButton/ModalSeconaryButton";
import ImageUpload from "../ImageUpload/ImageUpload";
import { StandardOption } from "../ReactSelect/ReactSelect";
import { FormGroup } from "../form/FormGroup/FormGroup";
import FormInput from "../form/FormInput/FormInput";
import FormModal from "../form/FormModal/FormModal";
import TIME_ZONES, { DAYLIGNT_SETTING, DEFAULT_TIME_ZONE } from "../../util/timezone.util";

const formModalClasses = {
  header: "modal-form-header",
  footer: "modal-form-footer",
  dialog: "modal-form-dialog",
  body: "modal-form-body",
};

export interface TspFleetFormInputs {
  companyName?: string;
  description?: string;
  companyAddress?: string;
  userName?: string;
  fullName?: string;
  phone?: string;
  password?: string;
  defaultLanguage?: StandardOption;
  timeZoneName?:StandardOption;
  adjustDaylightSaving?:StandardOption;    
  image?: string;
}

interface Props {
  headersTextVariant: "TSP" | "FLEET";
  operationType?: "ADD" | "EDIT";
  show: boolean;
  onSubmit: SubmitHandler<TspFleetFormInputs>;
  handleClose: () => void;
  isLoading?: boolean;
  submitDisabled?: boolean;
  defaultValues?: TspFleetFormInputs;
}

function AddEditTspFleetModalForm({
  headersTextVariant,
  operationType = "ADD",
  onSubmit,
  show,
  handleClose,
  isLoading,
  submitDisabled,
  defaultValues,
}: Props) {
  const { getLangNames } = useIntlContext();
  const { formatMessage } = useIntl();
  const { control, handleSubmit, setValue, trigger } = useForm<TspFleetFormInputs>({ reValidateMode: "onSubmit", defaultValues: getFormDefaults(getLangNames),});
  const passwordToggleRef = useRef<HTMLDivElement>(null);

  function generateAndSetPassword() {
    setValue("password", generatePassword());
    trigger("password");
    const passwordToggle = passwordToggleRef.current;
    if (passwordToggle?.getAttribute("data-shown-state") === "hidden") {
      passwordToggle.click();
    }
  }

  const renderBody = () => (
    <>
      <div className="d-flex align-items-center gap-3">
        <div className="flex-grow-1">
          <FormGroup
            label={<FormattedMessage id="COMPANY_NAME" defaultMessage="Company name" />}
            input={
              <FormInput
                input={{ disabled: isLoading }}
                type="text"
                name="companyName"
                control={control}
                rules={{ required: true }}
              />
            }
          />
          <FormGroup
            label={<FormattedMessage id="DESCRIPTION" defaultMessage="Description" />}
            input={
              <FormInput
                type="text"
                input={{
                  disabled: isLoading,
                  type: "textarea",
                  textareaRows: 6,
                  placeholder: formatMessage({
                    id: "INSERT_DESCRIPTION",
                    defaultMessage: "Insert description",
                  }),
                }}
                name="description"
                control={control}
              />
            }
          />
        </div>
        <ImageUpload
         
          placeHolderImg={
            defaultValues?.image ? (
              <img style={{ maxWidth: "100%", maxHeight: "100%" }} src={defaultValues.image} alt="company-logo" />
            ) : undefined
          }
          disabled={isLoading}
          onChange={async (img) => img.file && setValue("image", await toBase64(img.file))}
        />
      </div>
      <FormGroup
        label={<FormattedMessage id="COMPANY_ADDRESS" defaultMessage="Company Address" />}
        input={<FormInput type="text" name="companyAddress" control={control} input={{ disabled: isLoading }} />}
      />

      <FormGroup
        label={<FormattedMessage id="DEFAULT_LANGUAGE" defaultMessage="Default Language" />}
        input={
          <FormInput
            type="select"
            name="defaultLanguage"
            control={control}
            input={{
              options: getLangNames()!.map((l) => ({ label: l.long, value: l.short })),
              isDisabled: isLoading,
            }}
            rules={{ required: true }}
          />
        }
      />

      <FormGroup
        label={<FormattedMessage id="TIME_ZONE" defaultMessage="Time zone" />}
        input={
          <FormInput
            type="select"
            name="timeZoneName"
            control={control}
            input={{
              options: TIME_ZONES,
              isDisabled: isLoading,
            }}
            rules={{ required: true }}
          />
        }
      />

    <FormGroup
        label={<FormattedMessage id="DAYLIGHT_SAVING" defaultMessage="Time zone" />}
        input={
          <FormInput
            type="select"
            name="adjustDaylightSaving"
            control={control}
            input={{
              options: DAYLIGNT_SETTING,
              isDisabled: isLoading,
            }}
            rules={{ required: true }}
          />
        }
      />

      <div className="mt-4 mb-1">
        {headersTextVariant === "TSP" ? (
          operationType === "ADD" ? (
            <FormattedMessage id="TSP_MANAGER_DETAILS_MANDATORY" defaultMessage="TSP Manager Details(Mandatory)" />
          ) : (
            <FormattedMessage id="TSP_MANAGER_DETAILS" defaultMessage="TSP Manager Details" />
          )
        ) : operationType === "ADD" ? (
          <FormattedMessage id="FLEET_MANAGER_DETAILS_MANDATORY" defaultMessage="Fleet Manager Details(Mandatory)" />
        ) : (
          <FormattedMessage id="FLEET_MANAGER_DETAILS" defaultMessage="Fleet Manager Details" />
        )}
      </div>
      <FormGroup
        label={<FormattedMessage id="USER_NAME_EMAIL" defaultMessage="User Name (Email)" />}
        input={
          <FormInput
            type="text"
            name="userName"
            control={control}
            rules={{ required: operationType === "EDIT" ? false : true }}
            input={{ disabled: isLoading || operationType === "EDIT" }}
          />
        }
      />
      <FormGroup
        label={<FormattedMessage id="FULL_NAME" defaultMessage="Full Name" />}
        input={
          <FormInput
            type="text"
            name="fullName"
            control={control}
            rules={{ required: operationType === "EDIT" ? false : true }}
            input={{ disabled: isLoading || operationType === "EDIT" }}
          />
        }
      />
      <FormGroup
        label={<FormattedMessage id="PHONE" defaultMessage="Phone" />}
        input={
          <FormInput
            type="text"
            name="phone"
            control={control}
            rules={{ required: operationType === "EDIT" ? false : true }}
            input={{ disabled: isLoading || operationType === "EDIT", pattern: "[0-9]+" }}
          />
        }
      />
      <div className="d-flex align-items-end gap-2">
        <FormGroup
          className="flex-grow-1"
          label={<FormattedMessage id="PASSWORD" defaultMessage="Password" />}
          input={
            <FormInput
              type="text"
              name="password"
              control={control}
              input={{
                type: "password",
                disabled: isLoading || operationType === "EDIT",
                passwordToggleRef,
              }}
              rules={{required: operationType === "EDIT" ? false : true, validate: (pass) => (operationType === "ADD" ? pass && isPasswordValid(pass as string, true) : true) }}
            />
          }
        />
        {operationType === "ADD" && (
          <ModalSecondaryButton onClick={generateAndSetPassword} type="button" style={{ height: "2rem" }}>
            <FormattedMessage id="SUGGEST_PASSWORD" defaultMessage="Suggest Password" values={{ action: "Suggest" }} />
          </ModalSecondaryButton>
        )}
      </div>

     
    </>
  );

  return (
    <FormModal
      onSubmit={submitDisabled || isLoading ? undefined : handleSubmit(onSubmit)}
      id="add-edit-modal"
      classes={formModalClasses}
      header={
        <div className="d-flex align-items-center gap-2">
          <TspIcon fill={Color.GRAY_2} />
          {headersTextVariant === "TSP" ? (
            operationType === "ADD" ? (
              <FormattedMessage id="ADD_TSP" defaultMessage="Add TSP" />
            ) : (
              <FormattedMessage id="EDIT_TSP" defaultMessage="Edit TSP" />
            )
          ) : operationType === "ADD" ? (
            <FormattedMessage id="ADD_FLEET" defaultMessage="Add Fleet" />
          ) : (
            <FormattedMessage id="EDIT_FLEET" defaultMessage="Edit TSP" />
          )}
        </div>
      }
      show={show}
      handleClose={() => !isLoading && handleClose()}
      footer={
        <div className="d-flex align-items-center gap-2">
          <ModalSecondaryButton disabled={isLoading} type="button" onClick={handleClose}>
            <FormattedMessage id="CLOSE" defaultMessage="Close" />
          </ModalSecondaryButton>
          <ModalMainButton
            icon={<CheckVIcon fill={Color.DARKER_GREEN} />}
            type="submit"
            disabled={submitDisabled || isLoading}
          >
            {operationType === "ADD" ? (
              <FormattedMessage id="CREATE" defaultMessage="Create" />
            ) : (
              <FormattedMessage id="SAVE" defaultMessage="Save" />
            )}
          </ModalMainButton>
        </div>
      }
    >
      {renderBody()}
    </FormModal>
  );
}

export default AddEditTspFleetModalForm;



function getFormDefaults(  getLangNames: () => LangNamesType[]): TspFleetFormInputs {
 

  const language = { label: getLangNames()[0].long, value: getLangNames()![0].short };
  const currentTimezoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const timeZoneInfo = TIME_ZONES.find(x=>x.value === currentTimezoneName);

  return {
    companyName: undefined,
    description: undefined,
    companyAddress: undefined,
    userName: undefined,
    fullName: undefined,
    phone: undefined,
    password: undefined,
    defaultLanguage: language,
    image: undefined,
    timeZoneName :timeZoneInfo ?? DEFAULT_TIME_ZONE,
    adjustDaylightSaving: DAYLIGNT_SETTING[0]
  };
}

