import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToastContext } from "../context/ToastContext";
import { addFleetMutation, deleteFleetMutation, updateFleetMutation, updateFleetProfileMutation } from "../mutations/fleet.mutation";
import { AxiosResponse } from "axios";
import { Fleet, FleetCompanyProfile } from "../api/data-contracts";

interface UseFleetParams {
  onSuccessAdd?: (response: AxiosResponse<Fleet>) => void;
  onSuccessDelete?: () => void;
  onSuccessUpdate?: (response: AxiosResponse<Fleet>) => void;
  onSuccessUpdateProfile?: (response: AxiosResponse<FleetCompanyProfile>) => void;

}

function useFleet({ onSuccessAdd, onSuccessDelete, onSuccessUpdate,onSuccessUpdateProfile }: UseFleetParams = {}) {
  const { add: addToast } = useToastContext();
  const queryClient = useQueryClient();

  const {
    mutate: addFleet,
    isLoading: isLoadingAddFleet,
    isError: isErrorAddFleet,
  } = useMutation(
    addFleetMutation({
      onSuccess: (response) => {
        queryClient.invalidateQueries({ queryKey: ["fleet"] });
        queryClient.invalidateQueries({ queryKey: ["users"] });
        addToast({ type: "POSITIVE", content: "Added Fleet successfully" });
        onSuccessAdd?.(response);
      },
      onError: (error) => {
        let { response } = error;
        let data = response?.data as any
        let message = data?.Exception?.Message
        addToast({ type: "NEGATIVE", content: `Failed adding Fleet  ${ (message ? message : "" )}` });
      },
    })
  );

  const {
    mutate: deleteFleet,
    isLoading: isLoadingDeleteFleet,
    isError: isErrorDeleteFleet,
  } = useMutation(
    deleteFleetMutation({
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["fleet"] });
        queryClient.invalidateQueries({ queryKey: ["users"] });
        addToast({ type: "POSITIVE", content: "Deleted Fleet successfully" });
        onSuccessDelete?.();
      },
      onError: () => {
        addToast({ type: "NEGATIVE", content: "Failed deleting Fleet" });
      },
    })
  );

  const {
    mutate: updateFleet,
    isLoading: isLoadingUpdateFleet,
    isError: isErrorUpdateFleet,
  } = useMutation(
    updateFleetMutation({
      onSuccess: (response) => {
        queryClient.invalidateQueries({ queryKey: ["fleet"] });
        addToast({ type: "POSITIVE", content: "Updated Fleet successfully" });
        onSuccessUpdate?.(response);
      },
      onError: () => {
        addToast({ type: "NEGATIVE", content: "Failed updating Fleet" });
      },
    })
  );

  const {
    mutate: updateFleetProfile,
    isLoading: isLoadingUpdateFleetProfile,
    isError: isErrorUpdateFleetProfile,
  } = useMutation(
    updateFleetProfileMutation({
      onSuccess: (response) => {
        queryClient.invalidateQueries({ queryKey: ["fleet"] });
        queryClient.invalidateQueries({ queryKey: ["user"] });
        addToast({ type: "POSITIVE", content: "Updated Fleet successfully" });
        onSuccessUpdateProfile?.(response);
      },
      onError: () => {
        addToast({ type: "NEGATIVE", content: "Failed updating Fleet" });
      },
    })
  );

  return {
    addFleet,
    isLoadingAddFleet,
    isErrorAddFleet,
    deleteFleet,
    isLoadingDeleteFleet,
    isErrorDeleteFleet,
    updateFleet,
    isLoadingUpdateFleet,
    isErrorUpdateFleet,
    updateFleetProfile,
    isLoadingUpdateFleetProfile,
    isErrorUpdateFleetProfile
  };
}

export default useFleet;
