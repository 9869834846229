import { DateTimeFormat } from "./api/data-contracts";
import { ONE_MIN_IN_MS, dateTimeFormats } from "./util/date.util";

export class Constants {
  static readonly JwtKey = "token";
  static readonly UpdateLocalStorageEventKey = "local_storage_update";
  static readonly AuthStorageKey = "authenticated_user";
  static readonly FilterDebounceDelay = 300;
  static readonly DEFAULT_PAGESIZE = 10;
  static readonly TOAST_AUTOHIDE_DELAY = 5000;
  static readonly PAGINATION_DEBOUNCE_DELAY = 300;
  static readonly DEFAULT_BACK_DT_FORMAT = DateTimeFormat.YearMonthDayTwentyFourHours;
  static readonly DEFAULT_FRONT_DT_FORMAT = dateTimeFormats[2];
  static readonly DEFAULT_STALETIME = 5 * ONE_MIN_IN_MS;
  static readonly MAX_DEVICE_DRIVERS = 10;
  static readonly MAX_RULE_NAME_LENGTH = 50;
  static readonly MAX_RULE_DESCRIPTION_LENGTH = 250;
}

export class AppsConstants {
  static readonly HOME = "home";
}
export class RouteConstants {
  static readonly AUTH = "auth";
  static readonly LOGIN = "login";
  static readonly EVENTS_ON_MAP = "events-on-map";
  static readonly TRIPS = "trips";
  static readonly EVENTS_LIST = "events-list";
  static readonly MANAGEMENT = "management";
  static readonly DRIVERS = "drivers";
  static readonly USERS = "users";
  static readonly DEVICES = "devices";
  static readonly VEHICLES = "vehicles";
  static readonly SETTINGS = "settings";
  static readonly CONFIGURATION = "configuration";
  static readonly BILLING = "billing";
  static readonly NOTIFICATIONS = "rules";

}
export const BASE_ROUTES = [
  RouteConstants.AUTH,
  RouteConstants.MANAGEMENT,
  RouteConstants.EVENTS_ON_MAP,
  RouteConstants.EVENTS_LIST,
];

export class Color {
  static readonly DARK_GRAY_1 = "#353535";
  static readonly BLACK = "#000000";
  static readonly GRAY_5 = "#b5b5b5";
  static readonly CIPIA_BLUE = "#3587EB";
  static readonly BLUE_GRAY_2 = "#4E6582";
  static readonly WHITE = "white";
  static readonly GRAY_MEDIUM_3 = "#727272";
  static readonly LIGHT_GRAY_5 = "#E0E0E0";
  static readonly LIGHT_GREEN = "#53ffa7";
  static readonly DARKER_GREEN = "#00A450";
  static readonly RED = "#DF0031";
  static readonly CIPIA_BLUE_2 = "#379cd8";
  static readonly GRAY_2 = "#4D4D4D";
  static readonly LIGHT_BLUE = "#77b4ff";
  static readonly LOW_SEVERITY = "#F2BC00";
  static readonly HIGH_SEVERITY = "#DF0031";
  static readonly MEDIUM_SEVERITY = "#FF8000";
  static readonly LIGHT_LIGHT_BLUE = "#E1EFFF";
  static readonly LIGHT_LIGHT_GRAY = "#FAFAFA";
  static readonly LIGHT_GRAY_6 = "#EFEFEF";
  static readonly DARK_BLUE = "#1E3368";
}

export class InputUi {
  static readonly HEIGHT = "2rem";
  static readonly MULTI_STD_WIDTH = "9rem";
  static readonly SINGLE_STD_WIDTH = "7.5rem";
  static readonly SMALL_STD_WIDTH = "4rem";
}
