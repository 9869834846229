import { FormCheckProps } from "react-bootstrap";
import {
  FieldValues,
  useController,
  UseControllerProps,
} from "react-hook-form";
import { CheckBox } from "../CheckBox/CheckBox";
import { TextInput, TextInputProps } from "../TextInput/TextInput";
import SingleSelectInput, {
  SingleSelectInputProps,
} from "../SingleSelectInput/SingleSelectInput";
import { BaseOption } from "../../ReactSelect/ReactSelect";
// import ToggleSwitch, {
//   ToggleSwitchProps,
// } from "../shared/toggle-switch/ToggleSwitch";

type FormInputProps<Option extends BaseOption = BaseOption> =
  | {
      type: "text";
      input?: Omit<TextInputProps, "value">;
      className?: string;
      clearErrors?:()=>void;
    }
  // | {
  //     type: "toggleSwitch";
  //     input?: Omit<ToggleSwitchProps, "on">;
  //   }
  | {
      type: "checkbox" | "radio" | "switch";
      input?: Omit<FormCheckProps, "checked" | "type">;
      className?: string;
      clearErrors?:()=>void;
    }
  | {
      type: "select";
      input?: SingleSelectInputProps<Option>;
      className?: string;
      clearErrors?:()=>void;
    };

/* Note: for select input, the field value must be of type {value: your-choice, label: ReactNode} */
export default function FormInput<
  T extends FieldValues,
  Option extends BaseOption = BaseOption
>({
  type,
  input,
  className,
  clearErrors,
  ...controlProps
}: FormInputProps<Option> & UseControllerProps<T>) {
  const {
    field,
    fieldState: { error },
    formState: { isSubmitting },
  } = useController(controlProps);

  switch (type) {
    case "text":
      return (
        <TextInput
          {...field}
          {...input}
          disabled={isSubmitting || input?.disabled}
          value={field.value}
          isInvalid={!!error}
        />
      );
    // case "toggleSwitch":
    //   return (
    //     <ToggleSwitch
    //       {...field}
    //       {...input}
    //       disabled={isSubmitting || input?.disabled}
    //       on={field.value}
    //       isInvalid={!!error}
    //     />
    //   );
    case "checkbox":
    case "switch":
      return (
        <CheckBox
          type={type}
          {...field}
          {...input}
          checkClassName={className}
          disabled={isSubmitting || input?.disabled}
          checked={field.value}
          clearErrors={clearErrors}
          isInvalid={!!error}
        />
      );
    case "radio":
      return (
        <CheckBox
          type={type}
          {...field}
          {...input}
          disabled={isSubmitting || input?.disabled}
          checked={field.value}
          isInvalid={!!error}
        />
      );
    case "select":
      const { ref, ...fieldRest } = field;
      return (
        <SingleSelectInput<Option>
          selectRef={ref}
          {...fieldRest}
          {...input}
          isDisabled={isSubmitting || input?.isDisabled}
          options={input?.options}
          value={field.value}
          isInvalid={!!error}
        />
      );
  }
}
