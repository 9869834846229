import Table from "../Table/Table";
import { Row } from "react-table";
import useQueryUsers from "../../hooks/useQueryUsers";
import styles from "./UsersTable.module.scss";
import Pagination from "../Table/Pagination";
import { Role } from "../../api/data-contracts";
import { useMemo, useReducer, useState } from "react";
import { useUsersQueryParamsContext } from "../../context/QueryParamsContext/UsersQueryParamsContextProvider";
import UsersTableController from "./UsersTableController";
import { EMPTY_ARR } from "../../util/shared.util";
import { generateUsersColumns, queryResultToTableData } from "./users-table.util";
import { modalInitialState, modalReducer } from "../../util/reducers/modal-reducer.util";
import DeleteUserModal from "./modals/DeleteUserModal";
import AddEditUserModal from "./modals/AddEditUserModal";
import { SelectedType, useTspFleetContext } from "../../context/TspFleetContext/TspFleetContext";
import { useAuthContext } from "../../context/AuthContext";

export interface UserTableEntry {
  id?: string;
  fullName?: string;
  email?: string;
  role?: Role;
  phone?: string;
  company?: string;
  description?: string;
  lastAccessed?: string;
  tspId?: number | null;
  fleetId?: number | null;
}
type UserModalName = "ADD" | "EDIT" | "DELETE";
export interface UserModalData {
  users?: UserTableEntry[];
  tspTreeSelected?: SelectedType;
}

function UserTable() {
  const { queryParams, setPageIndex } = useUsersQueryParamsContext();
  const { data, isError, isLoading, isFetching } = useQueryUsers(queryResultToTableData);

  const [selectedRows, setSelectedRows] = useState<Row<UserTableEntry>[]>([]);
  const selectedUsers = useMemo(() => selectedRows.map((row) => row.original), [selectedRows]);

  const [modalState, dispatch] = useReducer(modalReducer<UserModalName, UserModalData>, modalInitialState);
  const userRole = useAuthContext().loggedUser?.role;
  const columns = useMemo(() => generateUsersColumns(userRole), [userRole]);
  const { selected: tspTreeSelected } = useTspFleetContext();

  return (
    <div className="h-100 d-flex flex-column">
      <div className="px-3">
        {userRole !== Role.FleetUser && (
          <UsersTableController
            usersCount={data?.totalCount}
            disable={{
              add: !tspTreeSelected && userRole !== Role.FleetManager,
              delete:
                selectedUsers.length === 0 ||
                !!selectedUsers.find((user) => user.role === Role.SpManager || user.role === Role.FleetManager),
              edit: selectedUsers.length !== 1,
            }}
            onClickAdd={() => {
              dispatch({
                type: "SET_SHOW",
                payload: { modalName: "ADD", data: { tspTreeSelected } },
              });
            }}
            onClickEdit={() =>
              dispatch({
                type: "SET_SHOW",
                payload: { modalName: "EDIT", data: { users: selectedUsers, tspTreeSelected } },
              })
            }
            onClickDelete={() =>
              dispatch({
                type: "SET_SHOW",
                payload: { modalName: "DELETE", data: { users: selectedUsers } },
              })
            }
          />
        )}
        <Table<UserTableEntry>
          data={data?.data || EMPTY_ARR}
          columns={columns}
          classes={{
            wrapper: () => styles["table-wrapper"],
            table: () => styles.table,
            thead: () => styles.thead,
            th: () => styles.th,
            tr: (row) => `${styles.tr} ${row.isSelected ? styles["selected-row"] : ""}`,
            td: () => styles.td,
          }}
          isLoading={isLoading}
          isError={isError}
          onChangeSelectedRows={setSelectedRows}
        />
      </div>
      <Pagination
        className="mt-auto"
        debouncedPageIndex={queryParams.paging.pageIndex}
        onChangePageIndex={setPageIndex}
        pageCount={data?.pageCount}
        allDisabled={isFetching}
        totalEntries={data?.totalCount}
      />
      {modalState.shownModal === "ADD" && (
        <AddEditUserModal
          onClickCancel={() => dispatch({ type: "CLEAR" })}
          onClickOk={() => dispatch({ type: "CLEAR" })}
          tspTreeSelected={modalState.data?.tspTreeSelected}
        />
      )}
      {modalState.shownModal === "EDIT" && (
        <AddEditUserModal
          onClickCancel={() => dispatch({ type: "CLEAR" })}
          onClickOk={() => dispatch({ type: "CLEAR" })}
          userInitialData={modalState.data?.users?.[0]}
          isEdit
          tspTreeSelected={modalState.data?.tspTreeSelected}
        />
      )}
      <DeleteUserModal
        show={modalState.shownModal === "DELETE"}
        onClickNo={() => dispatch({ type: "CLEAR" })}
        onClickYes={() => dispatch({ type: "CLEAR" })}
        usersData={modalState.data?.users!}
      />
    </div>
  );
}

export default UserTable;
