import { Fleet } from "../api/data-contracts";
import { EventsTableEntry } from "../components/EventsTable/EventsTable";
import { FleetEntry } from "../components/FleetView/FleetView";
import { isFleet, isTsp } from "../context/TspFleetContext/tsp-fleet-context.util";
import { SelectedType, Tsp } from "../context/TspFleetContext/TspFleetContext";
import { MapEvent } from "../models/map-event.model";

export function fleetEntryToMapEvent(data: FleetEntry[], selected?: SelectedType): MapEvent[] {
  return data.map(({ id, fleetName, lastEvent, licensePlate, vehicleType }) => {
    const {
      id: eventId,
      location,
      mediaArr,
      driverId,
      eventAttr1,
      heading,
      speed,
      time,
      tripId,
      type,
      category
    } = lastEvent ?? {};
    return {
      fleetId: selected && isFleet(selected) ? (selected as Fleet)?.id : undefined,
      tspId: selected && isTsp(selected) ? (selected as Tsp)?.id : undefined,
      deviceId: id,
      fleetName,
      lastEvent,
      licensePlate,
      vehicleType,
      eventId,
      driverId,
      tripId,
      time,
      type,
      speed,
      heading,
      mediaArr: mediaArr ?? [],
      location: {
        address: location?.address,
        time: location?.time,
        lat: location?.lat || 0,
        lng: location?.lng || 0,
      },
      eventAttr1,
      category: category
    };
  });
}

export function EventEntryToMapEvent(data: EventsTableEntry | null | undefined): MapEvent {
  const {
    messageId: eventId,
    locationAddr,
    locationLat,
    locationLong,
    locationTime,
    mediaArr,
    driverId,
    eventAttr1,
    speed,
    tripId,
    type,
    deviceId,
    vehicleId,
    vehicleType,
    headingAngle,
  } = data ?? {};
  return {
    fleetId:  undefined,
    tspId: undefined,
    deviceId,
    fleetName: "",
    licensePlate: vehicleId,
    vehicleType,
    eventId,
    driverId,
    tripId,
    time: locationTime,
    type,
    speed,
    heading: headingAngle,
    mediaArr: mediaArr ?? [],
    location: {
      address: locationAddr,
      time: locationTime,
      lat: locationLat || 0,
      lng: locationLong || 0,
    },
    eventAttr1,
  };
}
