import { Row, TableInstance } from "react-table";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Color, RouteConstants } from "../../constants";
import { onClickRow, filterForEventsOfDevice, filterForEventsDate } from "../FleetView/fleet-utils";
import { FleetEntry } from "../FleetView/FleetView";
import EventIcon from "../../icons/EventIcon";
import { Button } from "react-bootstrap";
import { getEventMetadata } from "../../util/events.util";
import { useFormattedLocalDate } from "../../hooks/useFormattedLocalDate";
import { useDevicesQueryParamsContext } from "../../context/QueryParamsContext/DevicesQueryParamsContextProvider";

interface ExpandedRowProps {
  row: Row<FleetEntry>;
  table: TableInstance<FleetEntry>;
  onClickEventType?: () => void;
  onClickTrips?: () => void;
}

const ExpandedRow = ({ row, table, onClickEventType, onClickTrips }: ExpandedRowProps) => {
  const values = row.original;
  const { getFormattedLocalDate } = useFormattedLocalDate();
  const {
    queryParams: { filters: devicesFilters },
  } = useDevicesQueryParamsContext();

  const filter = filterForEventsOfDevice(values.id);

  return (
    <tr>
      <td colSpan={table.visibleColumns.length} style={{ padding: "0" }}>
        <div className="h-100 pb-2 px-1 text-break">
          <div
            className="h-100 d-flex gap-2 px-3 pb-3 selected-row-bg cursor-pointer rounded-bottom-left rounded-bottom-right box-shadow-no-top"
            onClick={() => onClickRow(row, table)}
          >
            <div className="w-50 px-2 pt-1 pb-2 rounded d-flex flex-column bg-white">
              <div className="mb-2">
                <FormattedMessage id="LAST_KNOWN_LOCATION" defaultMessage="Last known location" />
              </div>
              <div style={{ fontWeight: 600 }}>
                {values.lastEvent?.location.time && getFormattedLocalDate(values.lastEvent?.location.time)}
              </div>
              <div>{values.lastEvent?.location.address}</div>
              <div>
                <Button disabled={!values.licensePlate} onClick={onClickTrips} variant="link" className="px-0 text-nowrap text-truncate w-auto">
                  <FormattedMessage id="TRIP_VIEW" defaultMessage="Trip view" />
                </Button>
              </div>
            </div>
            <div className="w-50 px-2 pt-1 pb-2 rounded d-flex flex-column bg-white">
              {values.lastEvent?.type && (
                <div className="d-flex flex-column gap-2">
                  <FormattedMessage id="LAST_EVENT" defaultMessage="Last Event" />
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <EventIcon serverName={values.lastEvent?.type} />
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        onClickEventType?.();
                      }}
                      variant="link"
                      className="px-0 text-nowrap text-truncate"
                    >
                      <div
                        style={{
                          color: Color.CIPIA_BLUE,
                          fontWeight: 700,
                          fontSize: "1.125rem",
                          textAlign: "left",
                        }}
                      >
                        {getEventMetadata(values.lastEvent?.type)?.displayName}
                      </div>
                      <div style={{ textAlign: "left" }}>{values.lastEvent?.eventAttr1 ?? ""}</div>
                    </Button>
                  </div>
                </div>
              )}

              <Link
                to={`../${RouteConstants.EVENTS_LIST}`}
                state={{ filters: [filter, ...filterForEventsDate(devicesFilters)] }}
                style={{ color: Color.CIPIA_BLUE, textDecoration: "underline" }}
              >
                <FormattedMessage id="ALL_EVENTS_FOR_THIS_VEHICLE" defaultMessage="All events for this vehicle" />
              </Link>
            </div>
          </div>
        </div>
      </td>
    </tr>
  );
};

export default ExpandedRow;
