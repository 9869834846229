import { ReactNode } from "react";
import { Dialog } from "@mui/material";

interface NestedModalProps {
  show: boolean;
  onClickNo: () => void;
  body: ReactNode;
}

function NestedModal({ show, onClickNo, body }: NestedModalProps) {
  return (
    <Dialog onClose={onClickNo} open={show}>{body}</Dialog>
  );
}

export default NestedModal;
