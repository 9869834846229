import { Node as TNode } from "react-checkbox-tree";
import { RuleSchedule, WeekDayName } from "../api/data-contracts";
import dayjs, { Dayjs } from "dayjs";

export interface ruleSchedules {
  selected: boolean;
  label: string;
  weekDay: WeekDayName;
  fromTime?: Dayjs;
  toTime?: Dayjs;
}

export function getVehiclesCheckboxTree(data: any): TNode[] {
  const vehiclesData = data
  .filter((x: any) => x.vehicleId !== null && x.vehicleId !== undefined) 
  .map((x: any) => ({ label: x.vehicleId, value: x.vehicleId, children: [] }));

  return [{
    label: "All", value: "",
    children: vehiclesData, className: "all-vehicles"
  }];
}

export function getRuleSchedules(formatMessage: any, data?: RuleSchedule[]): ruleSchedules[] {
  const sunExists = (data || []).find(x => x.weekDay === WeekDayName.Sunday);
  const monExists = (data || []).find(x => x.weekDay === WeekDayName.Monday);
  const tueExists = (data || []).find(x => x.weekDay === WeekDayName.Tuesday);
  const wedExists = (data || []).find(x => x.weekDay === WeekDayName.Wednesday);
  const thuExists = (data || []).find(x => x.weekDay === WeekDayName.Thursday);
  const friExists = (data || []).find(x => x.weekDay === WeekDayName.Friday);
  const satExists = (data || []).find(x => x.weekDay === WeekDayName.Saturday);



  const timeFormat = 'HH:mm:SS';

  return [{
    selected: !!sunExists,
    label: formatMessage({
      id: "ADD_EDIT_NOTIFICATION_FORM.RULE_FORM_SCHEDULE_SUN",
      defaultMessage: "Sun",
    }),
    weekDay: WeekDayName.Sunday,
    fromTime: sunExists ? dayjs(sunExists?.fromTime, timeFormat) : undefined,
    toTime: sunExists ? dayjs(sunExists?.toTime, timeFormat) : undefined,
  },
  {
    selected: !!monExists,
    label: formatMessage({
      id: "ADD_EDIT_NOTIFICATION_FORM.RULE_FORM_SCHEDULE_MON",
      defaultMessage: "Mon",
    }),
    weekDay: WeekDayName.Monday,
    fromTime: monExists ? dayjs(monExists?.fromTime, timeFormat) : undefined,
    toTime: monExists ? dayjs(monExists?.toTime, timeFormat) : undefined,
  },
  {
    selected: !!tueExists,
    label: formatMessage({
      id: "ADD_EDIT_NOTIFICATION_FORM.RULE_FORM_SCHEDULE_TUE",
      defaultMessage: "Tue",
    }),
    weekDay: WeekDayName.Tuesday,
    fromTime: tueExists ? dayjs(tueExists?.fromTime, timeFormat) : undefined,
    toTime: tueExists ? dayjs(tueExists?.toTime, timeFormat) : undefined,
  },
  {
    selected: !!wedExists,
    label: formatMessage({
      id: "ADD_EDIT_NOTIFICATION_FORM.RULE_FORM_SCHEDULE_WED",
      defaultMessage: "WEd",
    }),
    weekDay: WeekDayName.Wednesday,
    fromTime: wedExists ? dayjs(wedExists?.fromTime, timeFormat) : undefined,
    toTime: wedExists ? dayjs(wedExists?.toTime, timeFormat) : undefined,
  },
  {
    selected: !!thuExists,
    label: formatMessage({
      id: "ADD_EDIT_NOTIFICATION_FORM.RULE_FORM_SCHEDULE_THU",
      defaultMessage: "Thu",
    }),
    weekDay: WeekDayName.Thursday,
    fromTime: thuExists ? dayjs(thuExists?.fromTime, timeFormat) : undefined,
    toTime: thuExists ? dayjs(thuExists?.toTime, timeFormat) : undefined,
  },
  {
    selected: !!friExists,
    label: formatMessage({
      id: "ADD_EDIT_NOTIFICATION_FORM.RULE_FORM_SCHEDULE_FRI",
      defaultMessage: "Fri",
    }),
    weekDay: WeekDayName.Friday,
    fromTime: friExists ? dayjs(friExists?.fromTime, timeFormat) : undefined,
    toTime: friExists ? dayjs(friExists?.toTime, timeFormat) : undefined,
  },
  {
    selected: !!satExists,
    label: formatMessage({
      id: "ADD_EDIT_NOTIFICATION_FORM.RULE_FORM_SCHEDULE_SAT",
      defaultMessage: "Sat",
    }),
    weekDay: WeekDayName.Saturday,
    fromTime: satExists ? dayjs(satExists?.fromTime, timeFormat) : undefined,
    toTime: satExists ? dayjs(satExists?.toTime, timeFormat) : undefined,
  }];
}
