
import { FormattedMessage, useIntl } from "react-intl";
import styles from "./AddEditRuleModal.module.scss";
import FormInput from "../../../form/FormInput/FormInput";
import { FormGroup } from "../../../form/FormGroup/FormGroup";
import CheckboxTree from "react-checkbox-tree";
import { Color, Constants } from "../../../../constants";
import Arrow, { Direction } from "../../../../icons/Arrow";
import CheckboxInput from "../../../CheckboxInput/CheckboxInput";
import { useMemo, useState } from "react";
import { getRuleEventsCheckboxTree } from "../../../../util/rule-events.util";
import { useQueryAllDevices } from "../../../../hooks/useQueryDevices";
import { identity } from "../../../../util/shared.util";
import { Controller } from "react-hook-form";
import { getVehiclesCheckboxTree } from "../../../../util/rules.util";

interface IRuleInformationProps {
    form: any;
}

const eventOptionsTree = getRuleEventsCheckboxTree("all-events");

function AddEditRuleInformation({ form }: IRuleInformationProps) {
    const { control, formState: { errors } } = form;
    const [expanded, setExpanded] = useState<string[]>([""]);
    const [vehiclesexpanded, setVehiclesExpanded] = useState<string[]>([""]);

    const { formatMessage } = useIntl();

    const {
        dataAll,
        isLoading: isLoadingDevices,
        isError: isErrorDevices,
    } = useQueryAllDevices([], identity, true, true);

    const vehicleOptionsTree = useMemo(() => {
        if (isLoadingDevices || isErrorDevices) return getVehiclesCheckboxTree([]);
        return getVehiclesCheckboxTree(dataAll?.data || []);
    }, [dataAll, isLoadingDevices, isErrorDevices]);

    const [showNameMaxError, setShowNameMaxError] = useState(false);

    const validateNameMaxLength = (value: string) => {
        if (value?.length > Constants.MAX_RULE_NAME_LENGTH) {
            setShowNameMaxError(true);
            return false;
        }
        setShowNameMaxError(false);
        return true;
    }

    const [showDescriptionMaxError, setShowDescriptionMaxError] = useState(false);

    const validateDescriptionMaxLength = (value: string) => {
        if (value?.length > Constants.MAX_RULE_DESCRIPTION_LENGTH) {
            setShowDescriptionMaxError(true);
            return false;
        }
        setShowDescriptionMaxError(false);
        return true;
    }

    return (
        <>
            <div className={`${styles["rule-form-header"]} mb-1`}><FormattedMessage id="ADD_EDIT_NOTIFICATION_FORM.RULE_FORM_TITLE" /></div>
            <div className="d-flex flex-column gap-3">
                <FormGroup
                    label={
                        <div className="mb-2"><FormattedMessage id="ADD_EDIT_NOTIFICATION_FORM.RULE_FORM_NAME" defaultMessage="Rule Name" /><span className={styles.required}>*</span></div>}
                    key="name"
                    validation={showNameMaxError && <p className={`${styles["errorMsg"]} mb-0`}><FormattedMessage id="ADD_EDIT_NOTIFICATION_FORM.RULE_FORM_NAME_MAX-LENGTH_VALIDATION" defaultMessage={`Rule name cannot exceed ${Constants.MAX_RULE_NAME_LENGTH} characters`} values={{ length: Constants.MAX_RULE_NAME_LENGTH }} /></p>}
                    input={
                        <FormInput
                            type="text"
                            name="name"
                            control={control}
                            rules={{
                                required: true,
                                validate: validateNameMaxLength
                            }}
                            input={{
                                placeholder: formatMessage({
                                    id: "ADD_EDIT_NOTIFICATION_FORM.RULE_FORM_NAME_PLACEHOLDER",
                                    defaultMessage: "Give a meaningful name",
                                }),
                            }}
                        />
                    }
                />
                <FormGroup
                    label={
                        <div className="mb-2"><FormattedMessage id="DESCRIPTION" defaultMessage="Description" /></div>}
                    key="description"
                    validation={showDescriptionMaxError && <p className={`${styles["errorMsg"]} mb-0`}><FormattedMessage id="ADD_EDIT_NOTIFICATION_FORM.RULE_FORM_DESC_MAX-LENGTH_VALIDATION" defaultMessage={`Rule description cannot exceed ${Constants.MAX_RULE_DESCRIPTION_LENGTH} characters`} values={{ length: Constants.MAX_RULE_DESCRIPTION_LENGTH }} /></p>}
                    input={
                        <FormInput
                            type="text"
                            name="description"
                            control={control}
                            rules={{
                                required: false,
                                validate: validateDescriptionMaxLength
                            }}
                            input={{
                                placeholder: formatMessage({
                                    id: "ADD_EDIT_NOTIFICATION_FORM.RULE_FORM_DESCRIPTION_PLACEHOLDER",
                                    defaultMessage: "Give description",
                                },), type: "textarea", textareaRows: 3, maxlength: 250
                            }}
                        />
                    }
                />
                <FormGroup
                    label={
                        <div className={`${styles["events-title"]} mb-2`}><FormattedMessage id="ADD_EDIT_NOTIFICATION_FORM.RULE_FORM_EVENTS" defaultMessage="Select Events" /><span className={styles.required}>*</span></div>}
                    key="ruleEvents"
                    validation={errors.ruleEvents && <p className={`${styles["errorMsg"]} mb-0`}>{errors.ruleEvents.message}</p>}
                    input={
                        <div className={styles["events"]}>
                            <Controller
                                name="ruleEvents"
                                control={control}
                                rules={{
                                    required: formatMessage({
                                        id: "ADD_EDIT_NOTIFICATION_FORM.RULE_FORM_EVENTS_VALIDATION",
                                        defaultMessage: "Please select atleast one event",
                                    })
                                }}
                                render={({ field: { onChange, value, name } }) => (
                                    <CheckboxTree
                                        name={name}
                                        nodes={eventOptionsTree}
                                        checked={value as string[]}
                                        expanded={expanded}
                                        onCheck={(values) => {
                                            const sortedValues = [...values].sort();
                                            onChange(sortedValues);
                                        }}
                                        onExpand={setExpanded}
                                        showNodeIcon={false}
                                        icons={{
                                            expandOpen: <Arrow color={Color.BLACK} direction={Direction.Up} />,
                                            expandClose: <Arrow color={Color.BLACK} direction={Direction.Down} />,
                                            check: <CheckboxInput checked="FULL" />,
                                            halfCheck: <CheckboxInput checked="HALF" />,
                                            uncheck: <CheckboxInput checked="EMPTY" />,
                                        }}
                                    />
                                )}
                            />
                        </div>
                    }
                />
                <FormGroup
                    label={
                        <div className={`${styles["events-title"]} mb-2`}><FormattedMessage id="ADD_EDIT_NOTIFICATION_FORM.RULE_FORM_VEHICLES" defaultMessage="Select Vehicles" /><span className={styles.required}>*</span></div>}
                    key="ruleVehicles"
                    validation={errors.ruleVehicles && <p className={`${styles["errorMsg"]} mb-0`}>{errors.ruleVehicles.message}</p>}
                    input={
                        <div className={styles["events"]}>
                            <Controller
                                name="ruleVehicles"
                                control={control}
                                rules={{
                                    required: formatMessage({
                                        id: "ADD_EDIT_NOTIFICATION_FORM.RULE_FORM_VEHICLES_VALIDATION",
                                        defaultMessage: "Please select atleast one vehicle",
                                    })
                                }}
                                render={({ field: { onChange, value, name } }) => (
                                    <CheckboxTree
                                        name={name}
                                        nodes={vehicleOptionsTree}
                                        checked={value as string[]}
                                        expanded={vehiclesexpanded}
                                        onCheck={(values) => {
                                            if (vehicleOptionsTree[0].children && vehicleOptionsTree[0].children.length > 0) {
                                                const sortedValues = [...values].sort();
                                                onChange(sortedValues);
                                            }
                                        }}
                                        onExpand={setVehiclesExpanded}
                                        showNodeIcon={false}
                                        icons={{
                                            expandOpen: <Arrow color={Color.BLACK} direction={Direction.Up} />,
                                            expandClose: <Arrow color={Color.BLACK} direction={Direction.Down} />,
                                            check: <CheckboxInput checked="FULL" />,
                                            halfCheck: <CheckboxInput checked="HALF" />,
                                            uncheck: <CheckboxInput checked="EMPTY" />,
                                        }}
                                    />
                                )}
                            />
                        </div>
                    }
                />
            </div>
        </>
    )
}

export default AddEditRuleInformation;