import L, { LatLngExpression, LatLngTuple, Map } from "leaflet";
import { useCallback, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { MapContainer, Polyline, TileLayer, useMap } from "react-leaflet";
import { useDebouncedCallback } from "use-debounce";
import greenCirclePath from "../../assets/green_circle.svg";
import { useDevicesQueryParamsContext } from "../../context/QueryParamsContext/DevicesQueryParamsContextProvider";
import { useQueryAllDevices } from "../../hooks/useQueryDevices";
import { MapEvent } from "../../models/map-event.model";
import { queryResultToTableData } from "../FleetView/fleet-utils";
import styles from "./EventMap.module.css";
import EventMarker from "./EventMarker";
import { fleetEntryToMapEvent } from "../../util/events-map.util";
import { Color } from "../../constants";

const defaultCenter: LatLngTuple = [32.163924, 34.811593]; // Cipia office
const defaultZoom: number = 11; // valid values - 1..18

const MapControls = () => {
  const map = useMap();
  map.zoomControl.setPosition("bottomright");
  return <></>;
};

type EventMapProps = {
  events: MapEvent[];
  selectedId?: number;
  showEventsFilter?: boolean;
  showTripPath?: boolean;
  showAllMarkers: boolean;
  showMarkerPopup:boolean;
};

function EventMap({ events, selectedId, showEventsFilter, showTripPath, showAllMarkers, showMarkerPopup }: EventMapProps) {
  const [showAllEvents, setShowAllEvents] = useState(false);

  const { queryParams } = useDevicesQueryParamsContext();
  const { dataAll } = useQueryAllDevices(queryParams?.filters, queryResultToTableData, showAllEvents, showAllEvents, true);

  const dataAllEvents = dataAll?.data || [];
  const data = showAllEvents  ? fleetEntryToMapEvent(dataAllEvents) : events;

  const [mapRef, setMapRef] = useState<Map | null>();

  useEffect(() => {
    if (!mapRef) return;

    if (!data.length) {
      mapRef.fitBounds([defaultCenter], { padding: [50, 50] });
      return;
    }

    // Create an array of LatLng objects from marker data
    const latLngs = data.map((entry) => new L.LatLng(entry.location.lat!, entry.location.lng!));

    if (!latLngs.length) return;

    // Fit the map to the bounds of the markers
    mapRef.fitBounds(L.latLngBounds(latLngs), { padding: [50, 50] });
  }, [mapRef, data]);

  useEffect(() => {
    if (!mapRef || selectedId === undefined) return;

    
    let location = data.filter(evt => evt.eventId === selectedId)[0]?.location;
    location?.lat && location?.lng && mapRef.flyTo([location.lat, location.lng], mapRef.getZoom());
  }, [data, mapRef, selectedId]);

  const updateMapSize = useCallback(
    () => mapRef?.invalidateSize({ animate: true, duration: 10, pan: !!data.length }),
    [mapRef, data.length]
  );

  const debouncedUpdateMapSize = useDebouncedCallback(updateMapSize, 50);

  useEffect(() => {
    // This effect is used to listen for map container resize events
    if (!mapRef) return;

    const resizeObserver = new ResizeObserver((entries) => {
      // If map container width changed, resize the map to fit its container
      debouncedUpdateMapSize();
    });

    resizeObserver.observe(mapRef.getContainer());

    return () => resizeObserver.disconnect(); // clean up
  }, [mapRef, debouncedUpdateMapSize]);

  return (
    <MapContainer
      ref={(map) => setMapRef(map)}
      style={{ minWidth: "100%", zIndex: 0 }}
      zoom={defaultZoom}
      minZoom={3}
      scrollWheelZoom={true}
    >
      <MapControls />
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <>
        {data.map((entry, index) =>( entry.type !== "TripPath" || showAllMarkers)  && (
          <EventMarker key={index} index={index} data={entry} selectedId={selectedId} showMarkerPopup={showMarkerPopup} />
        ))}
        {showTripPath && (
          <Polyline
            positions={GetLatLngArray(data)}
            color={Color.DARK_BLUE}
          />
        )}
      </>
      {showEventsFilter && (
        <div className={`d-flex justify-content-center ${styles["button-container"]}`}>
          <Button
            size="sm"
            className={`toggle-button w-50 ${styles["left-button"]} ${showAllEvents ? "" : "active"}`}
            style={{ height: "2rem" }}
            onClick={() => setShowAllEvents(false)}
          >
            {!showAllEvents && <img className="green-circle" src={greenCirclePath} alt="gs" />}
            <span style={{ marginLeft: "0.25rem" }}>
              <FormattedMessage id="Map.PresentedEvents" defaultMessage="Presented Events" />
            </span>
          </Button>
          <Button
            size="sm"
            className={`toggle-button w-50 ${styles["right-button"]} ${showAllEvents ? "active" : ""}`}
            style={{ height: "2rem" }}
            onClick={() => setShowAllEvents(true)}
          >
            {showAllEvents && <img className="green-circle" src={greenCirclePath} alt="gs" />}
            <span style={{ marginLeft: "0.25rem" }}>
              <FormattedMessage id="Map.AllEvents" defaultMessage="All Events" />
            </span>
          </Button>
        </div>
      )}
    </MapContainer>
  );
}

export default EventMap;

function GetLatLngArray(data: MapEvent[]): LatLngExpression[] {
  return data.map((d) => {
    const ret: LatLngTuple = [d.location.lat!, d.location.lng!];
    return ret;
  });
}
