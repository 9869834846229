import { forwardRef, ReactNode } from "react";
import Form from "react-bootstrap/Form";
import { FormCheckProps } from "react-bootstrap/FormCheck";
import { uniqueId } from "../../../util/shared.util";
import styles from "./CheckBox.module.css";

export type CheckBoxType = "checkbox" | "radio" | "switch";

export type CheckBoxProps = {
  checkClassName?: string;
  className?: string;
  type?: CheckBoxType;
  checked?: boolean;
  label?: ReactNode;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  clearErrors?: () => void;
} & Omit<FormCheckProps, "id">;

export const CheckBox = forwardRef(
  (
    {
      label,
      type = "checkbox",
      checked,
      onChange,
      clearErrors,
      isInvalid,
      className,
      disabled,
      checkClassName,
      ...rest
    }: CheckBoxProps,
    ref
  ) => {
    const id = uniqueId("checkbox");

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange)
        onChange(event);

      if (clearErrors)
        clearErrors();

    }



    return (
      <Form.Group
        controlId={id}
        className={`${className ? className : ""
          } checkbox mb-0 d-flex align-items-center`}
      >
        <Form.Check
          // custom
          id={id}
          type={type}
          className={`${checkClassName ? styles[checkClassName] : ""} ${styles[type]} ${disabled ? styles.disabled : ""
            } font-md`}
          checked={checked ?? false}
          onChange={handleOnChange}
          disabled={disabled}
          ref={ref}
          {...rest}
        />
        <Form.Label
          className={`${styles.boxlabel} mb-0 user-select-none ${checked ? "font-weight-bold" : ""
            } ${!disabled ? "cursor-pointer" : ""}`}
        >
          {label}
        </Form.Label>
      </Form.Group>
    );
  }
);
