import { Row, Col } from "react-bootstrap";
import EventDetailHeader from "./EventDetailHeader";

const headers = [
    "EVENT_DETAILS",
    "VEHICLE_DETAILS",
    "DRIVER_DETAILS"
  ]

function EventDetailHeaders() {
    return (
        <Row>
        {headers.map(header => (
          <Col xl={4} key={header}><EventDetailHeader name={header} /></Col>
        ))}
      </Row>
    )
}

export default EventDetailHeaders;