import { Col, Modal, ModalBody, ModalFooter, Row, Spinner } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import ModalMainButton from "../../Buttons/ModalMainButton/ModalMainButton";
import { EventsTableEntry } from "../EventsTable";
import { queryResultToDetailsData } from "../events-util";
import { useQueryNextEvents } from "../../../hooks/useQueryEvents";
import EventIcon from "../../../icons/EventIcon";
import styles from "./EventDetailModal.module.scss";
import EventDetailMediaTabs from "./EventDetailMediaTabs";
import EventMap from "../../EventMap/EventMap";
import { useState } from "react";
import EventDetailHeaders from "./EventDetailHeaders";
import EventDetailItems from "./EventDetailItems";
import { useFormattedText } from "../../../hooks/useFormattedText";
import { EventEntryToMapEvent } from "../../../util/events-map.util";

interface EventDetailModallProps {
  onClickCancel: () => void;
  event?: EventsTableEntry;
  index: number;
}



function EventDetailModal({ event, index, onClickCancel }: EventDetailModallProps) {

  const [evetntPosition, setEventPosition] = useState(index);

  const { data, isLoading , isFetching} = useQueryNextEvents(queryResultToDetailsData, true, evetntPosition);
  const { getFormattedText } = useFormattedText();

  return (
    <Modal centered show dialogClassName="large-modal-form-dialog" onHide={onClickCancel} id="addNotificationModal">
      <Modal.Header closeButton className='modal-form-header'>
        <div className="d-flex align-items-center gap-1">
          <span className="d-inline-block">
            <EventIcon width="32px" serverName={data?.eventDetails?.type} />
          </span>
          <span className={`${styles.header} d-inline-block`}>
            {getFormattedText(data?.eventDetails?.type)}
          </span>
        </div>
      </Modal.Header>
      <ModalBody className="modal-form-body">

      {(isLoading || isFetching) ? (
        <div className={`${styles["modal-loader-height"]}`}>
           <Spinner as="span" animation="border" size="sm" className="m-auto" />
            Loading...
         </div>
      ) : 
        <>
        <EventDetailHeaders />
        <EventDetailItems event={data?.eventDetails} />
        <Row className="my-2">
          <Col xl={6} className={`${styles.mediaItem} p-0 pe-1`}>
            <EventDetailMediaTabs event={data?.eventDetails} />
          </Col>
          <Col xl={6} className={`${styles.mediaItem} p-0`}>
            <div className={`d-flex ${styles["h-90"]} w-100 ${styles["mt-2-4"]}`}>
              <div className="d-flex flex-grow-1">
                <EventMap
                  events={[EventEntryToMapEvent(data?.eventDetails)]}
                  selectedId={data?.eventDetails?.messageId}
                  showEventsFilter={false}
                  showTripPath={false}
                  showMarkerPopup={false}
                  showAllMarkers={true}
                />
              </div>
            </div>
          </Col>
        </Row>
        </>
      }
      </ModalBody>
      <ModalFooter className="py-1 modal-custom-footer">
        <ModalMainButton
          onClick={() => setEventPosition(evetntPosition - 1)}
          style={{ paddingLeft: "2.25rem", paddingRight: "2.25rem" }}
          disabled={evetntPosition <= 0}
        >
          <FormattedMessage id="PREVIOUS" defaultMessage="Previous" />
        </ModalMainButton>
        <ModalMainButton
          onClick={() => setEventPosition(evetntPosition + 1)}
          style={{ paddingLeft: "2.25rem", paddingRight: "2.25rem" }}
          disabled={(evetntPosition + 1) >= data?.totalCount!!}
        >
          <FormattedMessage id="NEXT" defaultMessage="Next" />
        </ModalMainButton>
      </ModalFooter>
    </Modal>
  );
}

export default EventDetailModal;
