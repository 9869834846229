import { CSSProperties, ReactNode, useMemo } from "react";
import { Table as BsTable } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Color } from "../../../constants";
import { useFormattedLocalDate } from "../../../hooks/useFormattedLocalDate";
import { useFormattedSpeed } from "../../../hooks/useFormattedSpeed";
import EventIcon from "../../../icons/EventIcon";
import VehicleIcon from "../../../icons/VehicleIcon";
import { MapEvent } from "../../../models/map-event.model";
import { getEventMetadata } from "../../../util/events.util";
import LastEventMedia from "./LastEventMedia";

interface Row {
  attr: ReactNode;
  ValueRenderer: (
    getFormattedDate: (date: string) => string,
    getSpeed: (speed: number) => string,
    speedUnit: ReactNode
  ) => ReactNode;
}

function getTableData(data: MapEvent): Row[] {
  return [
    {
      attr: <FormattedMessage id="TYPE" defaultMessage="Type" />,
      ValueRenderer: () => getEventMetadata(data.type)?.displayName + (data.eventAttr1 ? " - " + data.eventAttr1 : ""),
    },
    {
      attr: <FormattedMessage id="DEVICE_ID" defaultMessage="Device ID" />,
      ValueRenderer: () => data.deviceId,
    },
    {
      attr: <FormattedMessage id="DRIVER_ID" defaultMessage="Driver ID" />,
      ValueRenderer: () => data.driverId,
    },
    {
      attr: <FormattedMessage id="TIME" defaultMessage="Time" />,
      ValueRenderer: (getFormattedDate) => {
        const dt = data.time;
        return dt && getFormattedDate(dt);
      },
    },
    {
      attr: <FormattedMessage id="LOCATION" defaultMessage="Location" />,
      ValueRenderer: () => data.location.address,
    },
    {
      attr: <FormattedMessage id="SPEED" defaultMessage="Speed" />,
      ValueRenderer: (_, getSpeed, speedUnit) => {
        return (
          data.speed !== undefined && (
            <div>
              {getSpeed(data.speed)}
              {speedUnit}
            </div>
          )
        );
      },
    },
    {
      attr: <FormattedMessage id="HEADING" defaultMessage="Heading" />,
      ValueRenderer: () => data.heading !== undefined && String(data.heading) + "\u00B0",
    },
    {
      attr: <FormattedMessage id="LICENSE_PLATE" defaultMessage="License plate" />,
      ValueRenderer: () => data.licensePlate,
    },
    {
      attr: <FormattedMessage id="VEHICLE_TYPE" defaultMessage="Vehicle type" />,
      ValueRenderer: () => <VehicleIcon name={data.vehicleType} variant="selected" />,
    },
  ];
}

/* Component that renders information and media about the last event of a device */

interface LastEventWindowProps {
  className?: string;
  style?: CSSProperties;
  event?: MapEvent;
  onClose?: () => void;
}

function LastEventWindow({ className, style, onClose, event }: LastEventWindowProps) {
  const tableData: Row[] = useMemo(() => {
    return event ? getTableData(event) : [];
  }, [event]);

  const { getFormattedLocalDate } = useFormattedLocalDate();

  const { getSpeed, unit: speedUnit } = useFormattedSpeed();

  const { deviceId = "", eventId = -1, mediaArr = [], type, eventAttr1, time } = event ?? {};

  return (
    <div
      style={{
        border: "6px solid rgba(235, 244, 255, 1)",
        boxShadow: "box-shadow: 4px 0px 4px 0px rgba(0, 0, 0, 0.1)",
        ...style,
      }}
      className={`d-flex flex-column gap-1 ${className}`}
    >
      <div
        className="px-3 d-flex align-items-center gap-4"
        style={{
          borderBottom: "1px solid rgba(224, 224, 224, 1)",
          minHeight: "4rem",
        }}
      >
        {time && <div className="text-nowrap">{getFormattedLocalDate(time)}</div>}
        <div className="d-flex align-items-center gap-2">
          <EventIcon serverName={type} />
          <div>
            {getEventMetadata(type)?.displayName}
            <br />
            <>
              <div className="text-nowrap text-truncate">{eventAttr1 ?? ""}</div>
            </>
          </div>
        </div>
        <div className="ms-auto cursor-pointer" onClick={onClose}>
          {"\u2715"}
        </div>
      </div>
      {event && (
        <div className="px-3 mt-1" style={{ minHeight: "358px" }}>
          {deviceId && eventId && mediaArr.length && (
            <LastEventMedia
              deviceId={deviceId}
              messageId={eventId}
              mediaArr={mediaArr}
              eventType={type}
              subReason={eventAttr1}
            />
          )}
          <BsTable responsive>
            <tbody style={{ verticalAlign: "middle" }}>
              {tableData.map((row, i) => (
                <tr key={i}>
                  <td className="text-nowrap py-2" style={{ color: Color.GRAY_MEDIUM_3 }}>
                    {row.attr}
                  </td>
                  <td className="py-2">{row.ValueRenderer(getFormattedLocalDate, getSpeed, speedUnit)}</td>
                </tr>
              ))}
            </tbody>
          </BsTable>
        </div>
      )}
    </div>
  );
}

export default LastEventWindow;
