import { useForm } from "react-hook-form";
import FormModal from "../../../form/FormModal/FormModal";
import { RuleTableEntry } from "../../RulesTable";
import { FormattedMessage, useIntl } from "react-intl";
import { Color } from "../../../../constants";
import ModalMainButton from "../../../Buttons/ModalMainButton/ModalMainButton";
import ModalSecondaryButton from "../../../Buttons/ModalSecondaryButton/ModalSeconaryButton";
import { ReactComponent as CheckVIcon } from "../../../../assets/icons/CheckV.svg";
import { ReactComponent as NotifiationIcon } from "../../../../assets/icons/Notification.svg";
import styles from "./AddEditRuleModal.module.scss";
import { Container } from "react-bootstrap";
import RulesSteps from "./AddEditRuleStepper";
import { useEffect, useState } from "react";
import AddEditRuleInformation from "./AddEditRuleInformation";
import AddEditRuleSchedules from "./AddEditRuleSchedules";
import { getRuleSchedules, ruleSchedules } from "../../../../util/rules.util";
import useRule from "../../../../hooks/useRule";
import { NotificationType, RuleDto } from "../../../../api/data-contracts";
import { SelectedType } from "../../../../context/TspFleetContext/TspFleetContext";
import { isTsp, isFleet } from "../../../../context/TspFleetContext/tsp-fleet-context.util";
import { getIsFleetRole } from "../../../../util/roles.util";
import { useAuthContext } from "../../../../context/AuthContext";
import useQueryOwnFleet from "../../../../hooks/useQueryOwnFleet";
import { ruleQuery } from "../../../../queries/rule.query";
import { useQuery } from "@tanstack/react-query";
import ConfirmFormChangeModal from "../ConfirmFormChangeModal";

type NotificationActionModalProps = {
    onClickOk: () => void;
    onClickCancel: () => void;
    notificationInitialData?: RuleTableEntry;
    tspTreeSelected: SelectedType | undefined;
};

export interface INotificationsSteps {
    key: string;
    label: string;
}

const formModalClasses = {
    header: "modal-form-header",
    footer: "modal-custom-footer",
    dialog: "medium-modal-form-dialog",
    body: "modal-form-body",
};

interface ruleFormInputs {
    step?: string;
    name?: string;
    description?: string;
    ruleEvents: string[];
    ruleSchedules: ruleSchedules[];
    ruleVehicles: string[];
    ruleNotificationTypes: NotificationType[];
    ruleNotificationUsers: string[];
}

function AddEditRuleModal({
    onClickOk,
    onClickCancel,
    notificationInitialData,
    tspTreeSelected
}: NotificationActionModalProps) {

    const { formatMessage } = useIntl();

    const { addRule, isLoading } = useRule({ onSuccessAddRule: onClickOk });

    const { updateRule, isUpdateLoading } = useRule({ onSuccessUpdateRule: onClickOk });

    const { data: ruleData, isFetching: isRuleDataLoading } = useQuery(ruleQuery(notificationInitialData?.Id ?? 0, !!notificationInitialData?.Id));

    const form = useForm<ruleFormInputs>({
        reValidateMode: "onChange",
        defaultValues: {
            name: undefined,
            description: undefined,
            step: "ruleInformation",
            ruleSchedules: getRuleSchedules(formatMessage, ruleData?.data?.ruleSchedules || []),
            ruleNotificationTypes: [NotificationType.Email],
            ruleNotificationUsers: [],
            ruleVehicles: [],
        },
    });

    const { handleSubmit, setValue, formState: { isValid, dirtyFields,errors }, watch, setError, clearErrors } = form;
    const { loggedUser } = useAuthContext();
    const { data: ownFleet } = useQueryOwnFleet();

    useEffect(() => {
        if (ruleData?.data) {
            setValue('name', ruleData?.data?.name, { shouldValidate: true })
            setValue('description', ruleData?.data?.description, { shouldValidate: true })
            setValue('ruleSchedules', getRuleSchedules(formatMessage, ruleData?.data?.ruleSchedules || []), { shouldValidate: true })
            setValue('ruleEvents', (ruleData?.data?.ruleEvents || []).map(x => x.event_type), { shouldValidate: true })
            setValue('ruleNotificationTypes', (ruleData?.data?.ruleNotificationTypes || []).map(x => x.notification_type), { shouldValidate: true })
            setValue('ruleNotificationUsers', (ruleData?.data?.ruleNotificationUsers || []).map(x => x.userId), { shouldValidate: true })
            setValue('ruleVehicles', (ruleData?.data?.ruleVehicles || []).map(x => x.vehicleId), { shouldValidate: true })
            clearErrors();
        }
        // eslint-disable-next-line 
    }, [ruleData]);

    useEffect(() => {
        console.log(`errors : `,errors);
        // eslint-disable-next-line 
    }, [errors]);

    const onSubmit = async (formInputs: ruleFormInputs) => {
        if (!isValid)return;
        if (contentTypeValue === "ruleInformation") {
            setValue("step", "notificationSchedules");
            return;
        }

        if (contentTypeValue === "notificationSchedules") {
            if (!formInputs.ruleSchedules.some(x => x.selected)) {
                setError("ruleSchedules", { type: 'custom',  message: formatMessage({ id: "ADD_EDIT_NOTIFICATION_FORM.RULE_FORM_SCHEDULE_VALIDATION", defaultMessage: "Please select at-lease one notification schedule" }) });
                return;
            }
        }
        clearErrors();
        let tspId;
        let fleetId;
        if (ruleData?.data.Id) {
            tspId = ruleData?.data.tspId;
            fleetId = ruleData?.data.fleetId;
        } else {
            if (tspTreeSelected) {
                if (isTsp(tspTreeSelected)) {
                    tspId = tspTreeSelected.id;
                } else if (isFleet(tspTreeSelected)) {
                    tspId = tspTreeSelected.tspId;
                }
            }
            if (getIsFleetRole(loggedUser?.role)) {
                fleetId = ownFleet?.id;
                tspId = ownFleet?.tspId
            } else {
                fleetId = tspTreeSelected && isFleet(tspTreeSelected) ? tspTreeSelected.id : undefined;
            }
        }

        const postRule: RuleDto = {
            name: formInputs.name,
            description: formInputs.description,
            tspId,
            fleetId,
            ruleEvents: formInputs.ruleEvents.map(x => ({ event_type: x })),
            ruleNotificationTypes: formInputs.ruleNotificationTypes.map(x => ({ notification_type: x })),
            ruleNotificationUsers: formInputs.ruleNotificationUsers.map(x => ({ userId: x })),
            ruleSchedules: formInputs.ruleSchedules.filter(x => x.selected).map(y => ({ weekDay: y.weekDay, fromTime: y.fromTime?.format("HH:mm") || "", toTime: y.toTime?.format("HH:mm") || "" })),
            ruleVehicles: formInputs.ruleVehicles.map(x => ({ vehicleId: x })),
        };
        if (notificationInitialData?.Id) {
            await updateRule(postRule, notificationInitialData?.Id)
            return;
        }
        await addRule(postRule);
    }

    const contentTypeValue = watch("step");

    const [showAlert, setShowAlert] = useState(false);

    const handleCancel = () => {
        if (Object.keys(dirtyFields).length > 0) {
            setShowAlert(true);
            return;
        }
        onClickCancel();
    }
    return (
        <FormModal
            show
            id="addNotificationModal"
            handleClose={handleCancel}
            onSubmit={(handleSubmit(onSubmit))}
            classes={formModalClasses}
            header={
                <div className="d-flex gap-1">
                    <span className="d-inline-block">
                        <NotifiationIcon className={styles["notificationIcon"]} fill={Color.BLACK} />
                    </span>
                    <span className={`${styles.header} d-inline-block`}>
                        <FormattedMessage id={notificationInitialData ? "ADD_EDIT_NOTIFICATION_FORM.EDIT_USER_ACTION" : "ADD_EDIT_NOTIFICATION_FORM.ADD_USER_ACTION"} />
                    </span>
                </div>
            }
            footer={
                <>
                    <ModalSecondaryButton className={styles["button"]} type="button" disabled={isLoading || isRuleDataLoading || isUpdateLoading} onClick={handleCancel}>
                        <FormattedMessage id="CANCEL" defaultMessage="Cancel" />
                    </ModalSecondaryButton>
                    {contentTypeValue === "ruleInformation" ? <ModalMainButton type="submit" disabled={isRuleDataLoading || isUpdateLoading || !isValid} className={styles["button"]}>
                        <FormattedMessage id="NEXT" defaultMessage="Next" />
                    </ModalMainButton> :
                        <div className="d-flex align-items-center gap-2">
                            <ModalSecondaryButton disabled={isLoading || isRuleDataLoading || isUpdateLoading} className={styles["button"]} type="button" onClick={() => setValue("step", "ruleInformation")}>
                                <FormattedMessage id="BACK" defaultMessage="Back" />
                            </ModalSecondaryButton>
                            <ModalMainButton disabled={isLoading || isRuleDataLoading || isUpdateLoading || !isValid} className={styles["button"]} icon={<CheckVIcon fill={Color.DARKER_GREEN} />} type="submit">
                                <FormattedMessage id="SAVE" defaultMessage="Save" />
                            </ModalMainButton>
                        </div>
                    }
                </>
            }
        >
            <Container>
                <RulesSteps active={contentTypeValue} />
                <div className={`${styles.seperator} my-2`}></div>
                {contentTypeValue === "ruleInformation" ? <AddEditRuleInformation form={form} /> : <AddEditRuleSchedules form={form} />}
                <ConfirmFormChangeModal onClickNo={() => setShowAlert(false)} show={showAlert} onClickYes={onClickCancel} />
            </Container>

        </FormModal>
    );
}

export default AddEditRuleModal;