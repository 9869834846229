import styles from "./RulesTableController.module.css";
import { FormattedMessage } from "react-intl";
import addIconPath from "../../assets/icons/Add.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/Edit.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icons/Close.svg";
import { Color } from "../../constants";
import ActionButton from "../Buttons/ActionButton/ActionButton";
import { useAuthContext } from "../../context/AuthContext";
import { Role } from "../../api/data-contracts";

interface RulesControllerProps {
  onClickAdd: () => void;
  onClickEdit?: () => void;
  onClickDelete?: () => void;
  notificationsCount?: number;
  disable?: {
    add?: boolean;
    edit?: boolean;
    delete?: boolean;
  };
}

function RulesTableController({
  onClickAdd,
  onClickEdit,
  onClickDelete,
  notificationsCount,
  disable,
}: RulesControllerProps) {

  const userRole = useAuthContext().loggedUser?.role;

  return (
    <div className="d-flex gap-2 align-items-center my-3">

      {
        (userRole === Role.FleetManager || userRole === Role.FleetUser) &&

        <>
          <div className="header-font me-5">
            <FormattedMessage id="RULES" defaultMessage="Rules" />
            {` (${notificationsCount || 0})`}
          </div>
          <ActionButton
            icon={<img src={addIconPath} className={styles.btn_img} alt="add" />}
            onClick={onClickAdd}
            disabled={disable?.add}
            className={styles["action_btn"]}
          >
            <FormattedMessage id="CREATE" defaultMessage="Add" />
          </ActionButton>

          <ActionButton icon={<EditIcon fill={Color.CIPIA_BLUE} />} onClick={onClickEdit} disabled={disable?.edit} className={styles["action_btn"]}>
            <FormattedMessage id="EDIT" defaultMessage="Edit" />
          </ActionButton>

          <ActionButton icon={<DeleteIcon fill={Color.CIPIA_BLUE} />} onClick={onClickDelete} disabled={disable?.delete} className={styles["action_btn"]}>
            <FormattedMessage id="DELETE" defaultMessage="Delete" />
          </ActionButton>
        </>
      }
    </div>
  );
}

export default RulesTableController;
