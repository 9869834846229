import styles from "./AddEditRuleModal.module.scss";
import { FormattedMessage } from "react-intl";
import { Col, Row } from "react-bootstrap";
import FormInput from "../../../form/FormInput/FormInput";
import { DemoItem } from '@mui/x-date-pickers/internals/demo';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { Controller } from "react-hook-form";
import dayjs from "dayjs";

interface IRulesScheduleProps {
    form: any;
    item: any;
    index: any;
}

function RulesSchedule({ form, item, index }: IRulesScheduleProps) {
    const { control, watch, formState: { errors } , clearErrors } = form;
    const watchSchedules = watch(`ruleSchedules`);
    return (
        <Row className={`${styles["schedule-item"]} mt-2 ms-1 align-items-center`}>
            <Row key={`${item.id}-${index}`} className={`align-items-center pe-2`}>
                <Col xl={3}>
                    <Controller
                        render={({ field: { onChange, value, name } }) => <FormInput
                            type="checkbox"
                            name={name}
                            className="box"
                            input={{ label: <span className={`${styles["schedule-name"]} mt-1`}>{item.label}</span>, onChange, value: value }}
                            control={control}
                            clearErrors={()=>{clearErrors('ruleSchedules')}}
                        />}
                        name={`ruleSchedules.${index}.selected`}
                        control={control}
                    />
                </Col>
                <Col className="p-1">
                    <>
                        <Controller
                            render={({ field: { onChange, value } }) => <DemoItem label={<span className={styles["schedule-time-header"]}><FormattedMessage id="Time.FROM" defaultMessage="From" /></span>}>
                                <TimePicker value={value ? dayjs(value, "HH:mm") : null} onChange={(e) => onChange(e)} className={styles["timePicker"]} views={['hours', 'minutes']} />
                            </DemoItem>}
                            name={`ruleSchedules.${index}.fromTime`}
                            control={control}
                            rules={{ required: watchSchedules?.[index]?.selected }}
                        />
                        {errors.ruleSchedules?.[index]?.fromTime && <div className={styles["errorMsg"]}><FormattedMessage id="REQUIRED" defaultMessage="Required" /></div>}
                    </>
                </Col>
                <Col className="p-1">
                    <>
                        <Controller
                            render={({ field: { onChange, value } }) => <DemoItem label={<span className={styles["schedule-time-header"]}><FormattedMessage id="Time.TO" defaultMessage="To" /></span>}>
                                <TimePicker value={value ? dayjs(value, "HH:mm") : null} onChange={(e) => onChange(e)} className={styles["timePicker"]} views={['hours', 'minutes']} />
                            </DemoItem>}
                            name={`ruleSchedules.${index}.toTime`}
                            control={control}
                            rules={{
                                required: watchSchedules?.[index]?.selected,
                                validate: {
                                    afterFromTime: (value) => {

                                        if(!value){
                                            return true;
                                        }
                                        const fromTime = dayjs(watchSchedules?.[index]?.fromTime);
                                        if (!value || !fromTime.isValid()) {
                                            return "ADD_EDIT_NOTIFICATION_FORM.RULE_FORM_SCHEDULE_INVALID_TOTIME";
                                        }
                                        if (!dayjs(value).isAfter(fromTime) && !dayjs(value).isSame(fromTime)) {
                                            return "ADD_EDIT_NOTIFICATION_FORM.RULE_FORM_SCHEDULE_INVALID_FROMTIME_TOTIME";
                                        }
                                        return true;
                                    }
                                },
                            
                            }
                            }
                        />
                    
                    </>

                </Col>
            </Row>
            <div>
                {errors.ruleSchedules?.[index]?.toTime && <div className={styles["errorMsg"]}><FormattedMessage id={errors.ruleSchedules?.[index]?.toTime.message || "REQUIRED"} defaultMessage="Required" /></div>}
            </div>
        </Row>
        )
}

export default RulesSchedule;