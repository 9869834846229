import { UseMutationOptions } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { AddFleetData, DeleteFleetParams, UpdateFleetParams, UpdateFleetProfileParams, fleetService } from "../services/fleet.service";
import { Fleet, FleetCompanyProfile } from "../api/data-contracts";

type AddFleetMutationOptions = UseMutationOptions<AxiosResponse<Fleet>, AxiosError, AddFleetData>;
type DeleteFleetMutationOptions = UseMutationOptions<unknown, AxiosError, DeleteFleetParams>;
type UpdateFleetMutationOptions = UseMutationOptions<AxiosResponse<Fleet>, AxiosError, UpdateFleetParams>;
type UpdateFleetProfileMutationOptions = UseMutationOptions<AxiosResponse<FleetCompanyProfile>, AxiosError, UpdateFleetProfileParams>;

export const addFleetMutation = (
  useMutationProps: Omit<AddFleetMutationOptions, "mutationFn">
): AddFleetMutationOptions => ({
  mutationKey: ["fleet"],
  mutationFn: async (addFleetData: AddFleetData) => await fleetService.addFleet(addFleetData),
  ...useMutationProps,
});

export const deleteFleetMutation = (
  useMutationProps: Omit<DeleteFleetMutationOptions, "mutationFn">
): DeleteFleetMutationOptions => ({
  mutationKey: ["fleet"],
  mutationFn: async (params: DeleteFleetParams) => await fleetService.deleteFleet(params),
  ...useMutationProps,
});

export const updateFleetMutation = (
  useMutationProps: Omit<UpdateFleetMutationOptions, "mutationFn">
): UpdateFleetMutationOptions => ({
  mutationKey: ["fleet"],
  mutationFn: async (params: UpdateFleetParams) => {
    return await fleetService.updateFleet(params);
  },
  ...useMutationProps,
});

export const updateFleetProfileMutation = (
  useMutationProps: Omit<UpdateFleetProfileMutationOptions, "mutationFn">
): UpdateFleetProfileMutationOptions => ({
  mutationKey: ["fleet"],
  mutationFn: async (params: UpdateFleetProfileParams) => await fleetService.updateProfile(params),
  ...useMutationProps,
});
