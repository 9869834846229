import { useCallback, useMemo } from "react";
import { Measurement } from "../api/data-contracts";
import { meterSecToKmHour, meterSecToMph } from "../util/shared.util";
import { FormattedMessage } from "react-intl";
import { useProfile } from "./useProfile";
export const useFormattedSpeed = () => {
  const { measurement } = useProfile();
  const isImperial = measurement === Measurement.Imperial;

  const getSpeed = useCallback(
    (speed: number) => {
      let val;
      if (isImperial) {
        val = meterSecToMph(speed);
      } else {
        val = meterSecToKmHour(speed);
      }
      return val.toString();
    },
    [isImperial]
  );

  const unit = useMemo(
    () =>
      isImperial ? (
        <FormattedMessage id="MPH" defaultMessage="Mph" />
      ) : (
        <FormattedMessage id="KMH" defaultMessage="Km/h" />
      ),
    [isImperial]
  );

  return {
    getSpeed,
    unit,
    isImperial,
  };
};
