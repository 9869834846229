import { INotificationsSteps as IRulesSteps } from "./AddEditRuleModal";
import styles from "./AddEditRuleModal.module.scss";
import { ReactComponent as SeperatorIcon } from "../../../../assets/icons/Chevron.svg"
import { FormattedMessage } from "react-intl";

interface IRulesStepperProps {
    active?: string;
}

function RulesSteps({
    active,
}: IRulesStepperProps) {
    const steps : IRulesSteps[] = [{
        key: 'ruleInformation', label: 'ADD_EDIT_NOTIFICATION_FORM.RULE_INFO_TITLE'
    }, {
        key: 'notificationSchedules', label: 'ADD_EDIT_NOTIFICATION_FORM.SCHEDULE_INFO_TITLE'
    }];
    return (<div className="d-flex gap-2">
        {steps.map((step, index) => (
            <span key={step.label}>
                <span className={`${styles["notification-step"]} d-inline-block ${step.key === active ? styles["notification-step-active"] : ""}`}>
                    <FormattedMessage id={step.label} />
                </span>
                {index !== steps.length - 1 &&
                    <span className="d-inline-block">
                        <SeperatorIcon />
                    </span>}
            </span>
        ))}

    </div>)
}

export default RulesSteps;