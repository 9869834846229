import { FormattedMessage } from "react-intl";
import styles from "./EventDetailModal.module.scss";

interface EventDetailHeaderProps {
    name: string;
}

function EventDetailHeader({ name }: EventDetailHeaderProps) {
    return (
        <>
            <span className={`${styles["headerText"]}`}>
                <FormattedMessage id={name} />
            </span>
            <div className={`${styles["seperator"]} mt-2`}></div>
        </>
    )
}

export default EventDetailHeader;