/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { GetListResponseRule, Rule, RuleDto } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class RuleManagement<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description Add the data of the rule.
   *
   * @tags Rule Management
   * @name AddRule
   * @summary Add a Rule data.
   * @request POST:/rule
   * @secure
   */
  addRule = (data: RuleDto, params: RequestParams = {}) =>
    this.request<Rule, void>({
      path: `/rule`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description The API retrieves a list of rules configured on the FSCloud for the fleets. Each item in the list includes details about the Rule, such as the rule id, rule name, description and who etc.
   *
   * @tags Rule Management
   * @name GetRules
   * @summary Get list of rule available
   * @request GET:/rule
   * @secure
   */
  getRules = (
    query?: {
      /**
       * A string representing filters to narrow down the list of devices returned.
       * The filters are in the form "field operator value", where field is one
       * of "id", "vehicleId", "time<", "time>", or "ownerEmail", operator is one
       * of "==" or "!=", and value is the value to compare against. Multiple
       * filters can be combined using the "&" character.
       * Filter can contain SP-ids and\or fleet-Ids.
       * To filter by Tsp/Fleet user TspId or FleetId
       * @example "id==myDevice"
       */
      filters?: string;
      /**
       * A string representing the fields to sort the list of devices by.
       * The fields are the same as the filters fields, and the order is specified
       * using the "-" character for descending order or "+" character for
       * ascending order. Multiple fields can be combined using the "," character.
       * @example "-vehicleId"
       */
      sorts?: string;
      /**
       * An integer representing the page number of the list of items to return.
       * The default is 1.
       * @default 1
       * @example 1
       */
      page?: number | null;
      /**
       * An integer representing the number of items per page in the list.
       * The default is 10.
       * @default 10
       * @example 10
       */
      pageSize?: number | null;
    },
    params: RequestParams = {},
  ) =>
    this.request<GetListResponseRule, void>({
      path: `/rule`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Update the data of the rule.
   *
   * @tags Rule Management
   * @name UpdateRule
   * @summary Update a Rule data.
   * @request PUT:/rule/{ruleId}
   * @secure
   */
  updateRule = (ruleId: number, data: RuleDto, params: RequestParams = {}) =>
    this.request<Rule, void>({
      path: `/rule/${ruleId}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description Remove a Rule from the FSCloud registry.
   *
   * @tags Rule Management
   * @name DeleteRule
   * @summary Delete a Rule
   * @request DELETE:/rule/{ruleId}
   * @secure
   */
  deleteRule = (ruleId: number, params: RequestParams = {}) =>
    this.request<void, void>({
      path: `/rule/${ruleId}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * @description Retrieve information about the rule with events, schedule and vehicle
   *
   * @tags Rule Management
   * @name GetRule
   * @summary Retrieve Rule information
   * @request GET:/rule/{ruleId}
   * @secure
   */
  getRule = (ruleId: number, params: RequestParams = {}) =>
    this.request<RuleDto, void>({
      path: `/rule/${ruleId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
}
