import { useMutation, useQueryClient } from "@tanstack/react-query";
import { RuleDto } from "../api/data-contracts";
import { useToastContext } from "../context/ToastContext";
import { addRuleMutation, deleteRuleMutation, updateRuleMutation } from "../mutations/rule.mutation";

interface UseRulesProps {
  onSuccessAddRule?: () => void;
  onSuccessUpdateRule?: () => void;
}

function useRule({
  onSuccessAddRule,
  onSuccessUpdateRule
}: UseRulesProps = {}) {
  const { add: addToast } = useToastContext();
  const queryClient = useQueryClient();
  const { mutate: mutateAddRule, isLoading, isError } = useMutation(addRuleMutation());
  const { mutate: mutateUpdateRule, isLoading: isUpdateLoading, isError: isUpdateError } = useMutation(updateRuleMutation());
  const { mutate: mutateDeleteRule, isLoading: isDeleteLoading, isError: isDeleteError } = useMutation(
    deleteRuleMutation({
      onSuccess: (_, { name }) => {
        addToast({ type: "POSITIVE", content: `Rule ${name} deleted Successfully` });
      },
      onError: (_, { name }) => {
        addToast({
          type: "NEGATIVE",
          content: `Failed deleting Rule ${name}`,
        });
      },
    })
  );
  

  async function addRule(rule: RuleDto): Promise<void> {
    mutateAddRule(
      { rule },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: ["notifications"] });
          addToast({ type: "POSITIVE", content: `A new notification rule was successfully added.` });
          onSuccessAddRule?.();
        },
        onError: (error) => {
          let { response } = error;
          let data = response?.data as any;
          let message = data?.Exception?.Message;
          addToast({ type: "NEGATIVE", content: `Failed to add a new notificaiton rule. ${message ? message : ""}` });
        },
      }
    );
  }

  async function updateRule(rule: RuleDto, ruleId: number): Promise<void> {
    mutateUpdateRule(
      { rule, ruleId },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: ["notifications"] });
          addToast({ type: "POSITIVE", content: `Notification rule saved successfully` });
          onSuccessUpdateRule?.();
        },
        onError: () => {
          addToast({ type: "NEGATIVE", content: `Failed to update a notificaiton rule.` });
        },
      }
    );
  }

  async function deleteRule(ruleList: RuleDto[]): Promise<void> {
    for (let index = 0; index < ruleList.length; index++) {
      await mutateDeleteRule(
        { Id:ruleList[index].Id, name: ruleList[index].name},
        {
          onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["notifications"] });
          }
        }
      );
    }
  }
  return {
    addRule,
    updateRule,
    isLoading,
    isError,
    isUpdateLoading,
    isUpdateError,
    deleteRule,
    isDeleteLoading,
    isDeleteError
  };
}

export default useRule;
