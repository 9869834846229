
import { FormattedMessage, useIntl } from "react-intl";
import styles from "./AddEditRuleModal.module.scss";
import { FormGroup } from "../../../form/FormGroup/FormGroup";
import RulesSchedule from "./NotificationScheduleItem";
import { Col, Form, Row } from "react-bootstrap";
import { SearchBox } from "../../../form/SearchBox/SearchBox";
import { ReactComponent as EmailIcon } from "../../../../assets/icons/Email.svg";
import { useQueryAllFleetUsers } from "../../../../hooks/useQueryUsers";
import { useMemo, useState } from "react";
import { useFieldArray } from "react-hook-form";
import { queryResultToTableData } from "../../../UsersTable/users-table.util";
import { NotificationType } from "../../../../api/data-contracts";


interface IRuleInformationProps {
    form: any;
}

function AddEditRuleSchedules({ form }: IRuleInformationProps) {
    const { control, register, formState: { errors } } = form;

    const { formatMessage } = useIntl();

    const { fields } = useFieldArray({
        control,
        name: "ruleSchedules",
    });

     const sendingTypes = [
    // {
    //     notification_type: NotificationType.SMS, icon: <SMSIcon />, disabled: true
    // },
    {
        notification_type: NotificationType.Email, icon: <EmailIcon />, disabled: true
    },
    // {
    //     notification_type: NotificationType.WhatsApp, icon: <WhatsappIcon />, disabled: true
    // }
];

    const { data } = useQueryAllFleetUsers(queryResultToTableData);

    const [search, setSearch] = useState("");

    const searchedUsers = useMemo(() => {
        return (data?.data || []).filter(x => (x.fullName || "").toLowerCase().includes(search.toLowerCase()))
    }, [data, search]);

    return (
        <>
            <div className="d-flex flex-column gap-3">
                <FormGroup
                    label={
                        <div className={`${styles["rule-form-header"]} mb-1`}><FormattedMessage id="ADD_EDIT_NOTIFICATION_SCHEDULES.RULE_FORM_TITLE" defaultMessage="Send notification only at the times allowed below" /><span className={styles.required}>*</span></div>}
                    key="ruleSchedules"
                    validation={errors.ruleSchedules && <p className={`${styles["errorMsg"]} mb-0 ms-2 mt-1`}>{errors.ruleSchedules.message}</p>}
                    input={
                        <Row className="align-items-center">
                            {
                                fields.map((item: any, index: number) => (
                                    <Col xl={6} key={item.weekDay}>
                                        <RulesSchedule form={form} item={item} index={index} />
                                    </Col>
                                ))
                            }
                        </Row>
                    }
                />
                <div className={`${styles["events-title"]} mb-1`}><FormattedMessage id="ADD_EDIT_NOTIFICATION_FORM.RULE_FORM_SEND_NOTIFICATION" defaultMessage="Send notification to" /><span className={styles.required}>*</span></div>
                <div className={`${styles["events"]} p-3`}>
                    <SearchBox
                        placeHolderId="ADD_EDIT_NOTIFICATION_SCHEDULES.USER_NAME_PLACEHOLDER"
                        defaultPlaceHolderMessage="Type user name"
                        onChange={(e) => setSearch(e.target.value)}
                        className="mb-0"
                    />
                    <div className="d-flex flex-column gap-2 mt-3">
                        {(searchedUsers || []).map(x => (
                            <Form.Group  key={x.id} className={`${styles["user"]} pb-2 checkbox mb-0 d-flex align-items-center`}>
                                <Form.Check
                                    type="checkbox"
                                    value={x.id}
                                    className={`${styles.box} ${styles["checkbox"]} font-md`}
                                    {...register("ruleNotificationUsers", {
                                        required: formatMessage({ id: "ADD_EDIT_NOTIFICATION_FORM.RULE_FORM_SEND_NOTIFICATION_VALIDATION", defaultMessage: "Please select at-least one user" })
                                    })}
                                />
                                <Form.Label
                                    className={`${styles.boxlabel} mb-0 user-select-none`}
                                >
                                    {x.fullName}
                                </Form.Label>
                            </Form.Group>
                        ))}
                    </div>
                </div>
                {errors.ruleNotificationUsers && <p className={`${styles["errorMsg"]} mb-0`}>{errors.ruleNotificationUsers.message}</p>}
                <Row className="align-items-center gap-3">
                    <Col xl={2} className={`${styles["events-title"]}`}>
                        <FormattedMessage id="ADD_EDIT_NOTIFICATION_FORM.RULE_FORM_SEND_BY" defaultMessage="Send By" />
                        <span className={styles.required}>*</span>
                    </Col>
                    <Col>
                        <div className="d-flex align-items-center gap-4">
                            {sendingTypes.map(x => (
                                <Form.Group
                                    key={x.notification_type}
                                    controlId={x.notification_type}
                                    className={"checkbox mb-0 d-flex align-items-center"}
                                >
                                    <Form.Check
                                        disabled={x.disabled}
                                        type="checkbox"
                                        value={x.notification_type}
                                        className={`${styles.box} ${styles["checkbox"]} font-md`}
                                        {...register("ruleNotificationTypes", {
                                            required: false
                                        })}
                                    />
                                    <Form.Label className={`${styles.boxlabel} mb-0 user-select-none`}>
                                        <div className="d-flex align-items-center gap-1">{x.icon}<span>{x.notification_type}</span></div>
                                    </Form.Label>
                                </Form.Group>
                            ))}
                        </div>
                    </Col>
                </Row>
                {errors.ruleNotificationTypes && <p className={`${styles["errorMsg"]} mb-0`}>{errors.ruleNotificationTypes.message}</p>}
            </div>
        </>
    )
}

export default AddEditRuleSchedules;