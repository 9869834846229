import moment from 'moment-timezone';
import { StandardOption } from '../components/ReactSelect/ReactSelect';


export const DEFAULT_TIME_ZONE = {label:"(UTC+00:00) Etc/Universal",value:"Etc/Universal"};

export const DAYLIGNT_SETTING :StandardOption[] = [
  {
    label: "Enabled",
    value: "1"
  },
  {
    label: "Disabled",
    value: "0"
  }
]

const TIME_ZONES: StandardOption[] = moment.tz.names()
  .map(tz => {
    const now = moment().tz(tz); // Current time in that timezone
    const offset = now.format('Z'); // Get UTC offset as ±HH:mm

    return {
      label: `(UTC${offset}) ${tz}`, // Format offset as ±HH:mm
      value: tz
    };
  })
  .sort((a, b) => {
    const offsetA = moment().tz(a.value).utcOffset();
    const offsetB = moment().tz(b.value).utcOffset();
    return offsetA - offsetB; // Sort by offset in minutes
  });

  export default TIME_ZONES;

