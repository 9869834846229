import { QueryFunctionContext } from "@tanstack/react-query";
import {  GetRulesParams, GetRulesResult, ruleService } from "../services/rule.service";

export function rulesQuery<selectedT>(
  params: GetRulesParams,
  selector: (queryResult: GetRulesResult) => selectedT,
  enabled: boolean
) {
  return {
    queryKey: ["notifications", params] as const,
    queryFn: async (context: QueryFunctionContext<readonly ["notifications", GetRulesParams]>) =>
      await ruleService.getRules(context.queryKey[1]),
    select: selector,
    enabled: enabled,
    retry: false
  };
}

export function ruleQuery(id: number, enabled: boolean) {
  return {
    queryKey: ["notification", id] as const,
    queryFn: async (context: QueryFunctionContext<readonly ["notification", number]>) =>
      await ruleService.getRule(context.queryKey[1]),
    staleTime: Infinity,
    enabled: enabled,
  };
}
