import { FormattedMessage } from "react-intl";
import { Col, Row } from "react-bootstrap";
import { EventsTableEntry } from "../EventsTable";
import EventDetailItem from "./EventDetailItem";
import { useFormattedLocalDate } from "../../../hooks/useFormattedLocalDate";
import { useFormattedSpeed } from "../../../hooks/useFormattedSpeed";
import VehicleIcon from "../../../icons/VehicleIcon";

interface EventDetailItemsProps {
    event?: EventsTableEntry | null | undefined;
}

function EventDetailItems({ event }: EventDetailItemsProps) {
    const { getFormattedLocalDate } = useFormattedLocalDate();
    const { getSpeed, isImperial } = useFormattedSpeed();
    return (
        <>
            <Row>
                <Col xl={4}><EventDetailItem name={"DATE_AND_TIME"} value={getFormattedLocalDate(event?.dateTime!!)} /></Col>
                <Col xl={4}><EventDetailItem name={"LIC_PLATE"} value={event?.vehicleId ?? "N/A"} /></Col>
                <Col xl={4}><EventDetailItem name={"DRIVER_NAME"} value={event?.driverName} /></Col>
            </Row>
            <Row>
                <Col xl={4}><EventDetailItem name={"ADDRESS"} value={`${event?.locationAddr}`} /></Col>
                <Col xl={4}><EventDetailItem name={"DEVICE_ID"} value={event?.deviceId} /></Col>
                <Col xl={4}><EventDetailItem name={"LICENSE_ID"} value={event?.driverId} /></Col>
            </Row>
            <Row>
                <Col xl={4}><EventDetailItem name={"LOC_TIME"} value={getFormattedLocalDate(event?.locationTime!!)} /></Col>
                <Col xl={4}><EventDetailItem name={"VEHICLE_TYPE"} value={<div className="d-flex align-items-center gap-1">
                    <VehicleIcon name={event?.vehicleType} /><span>{event?.vehicleType?.toString() !== "0" ? event?.vehicleType : ""}</span>
                </div>} /></Col>
            </Row>
            <Row>
                <Col xl={4}><EventDetailItem name={"SPEED"} value={<div className="d-flex align-items-center gap-1"><span>{getSpeed(event?.speed!!)}</span><span>
                    {isImperial ? (
                        <FormattedMessage id="MPH" defaultMessage="mph" />
                    ) : (
                        <FormattedMessage id="KMH" defaultMessage="km/h" />
                    )}</span></div>} /></Col>
            </Row>
        </>
    )
}

export default EventDetailItems;