import { Form } from "react-bootstrap";
import { ReactComponent as SearchIcon } from "../../../assets/icons/Search.svg";
import { useIntl } from "react-intl";

type SearchBoxProps = {
  search?: string;
  placeHolderId: string;
  defaultPlaceHolderMessage: string;
  className?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
};

export const SearchBox = ({ search, placeHolderId, defaultPlaceHolderMessage, onChange, className="mb-4" }: SearchBoxProps) => {
  const { formatMessage } = useIntl();
  let placeHolder = formatMessage({ id: placeHolderId, defaultMessage: defaultPlaceHolderMessage });
  return (
    <div>
      <div className="ms-2 mt-1  position-absolute">
        <SearchIcon />
      </div>
      <Form.Control
        className={`${className} input-with-label-container`}
        style={{ paddingLeft: "35px" }}
        placeholder={placeHolder}
        onChange={onChange}
        value={search}
      />
    </div>
  );
};
