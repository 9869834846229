import { ReactNode, useEffect, useRef, useState } from "react";
import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import styles from "./OverlayItem.module.scss";
import { FormattedMessage } from "react-intl";
import React from "react";
import { RuleSchedule } from "../../api/data-contracts";

interface OverlayItemProps {
    value: ReactNode | number | string | undefined | null;
    position: "top" | "bottom" | "right" | "left";
    className?: string;
}

interface OverlayScheduleItemProps {
    value: RuleSchedule[];
}



export function OverlayItemCustom({ value, position, className  }: OverlayItemProps) {
    const scheduleRef: any = useRef(null);
    return (
        <OverlayTrigger
            placement={position}
            overlay={<Tooltip>{value}</Tooltip>}
            trigger={true ? 'hover' : []} // Use an array for no triggers
        >
            <div ref={scheduleRef} className={`${styles["overlayText"]} ${className}`}>{value}</div>
        </OverlayTrigger>
    )
}


export function OverlayItem({ value, position, className }: OverlayItemProps) {

    const scheduleRef: any = useRef(null);
    const [isOverflowing, setIsOverflowing] = useState(false);

    useEffect(() => {
        if (scheduleRef.current) {
            setIsOverflowing(scheduleRef.current.scrollWidth > scheduleRef.current.clientWidth);
        }
    }, [value]);

    return (
        <OverlayTrigger
            placement={position}
            overlay={<Tooltip>{value}</Tooltip>}
            trigger={isOverflowing ? ['hover', 'focus'] : []} // Use an array for no triggers
        >
            <div ref={scheduleRef} className={`${isOverflowing ? styles["overlayText"] : ""} ${className}`}>{value}</div>
        </OverlayTrigger>
    )
}

export function OverlayItemSchedule({ value }: OverlayScheduleItemProps) {

    const scheduleRef: any = useRef(null);
    const [isOverflowing, setIsOverflowing] = useState(false);

    useEffect(() => {
        if (scheduleRef.current) {
            setIsOverflowing(scheduleRef.current.scrollWidth > scheduleRef.current.clientWidth);
        }
    }, [value]);

    return (
     <OverlayTrigger
        placement="top"
        offset={[30, 0]}
        trigger={isOverflowing ? ['hover', 'focus'] : []}
        overlay={
          <Tooltip>
            {value && value.length > 0 &&
              <Row>
                <Col xl="4" style={{ color: "#B5B5B5", marginBottom: "5px", textAlign: "left" }}>
                <FormattedMessage id="DAY" defaultMessage="Day" />
                </Col>
                <Col xl="8" style={{ color: "#B5B5B5", marginBottom: "5px", textAlign: "left" }}>
                <FormattedMessage id="NOTIFICATIONS_TABLE.SCHEDULE" defaultMessage="Schedule" />
                </Col>
                {
                value.map((x: any, index: number) => (
                  <React.Fragment key={index}>
                    <Col xl="4" style={{ textAlign: "left" }}>
                      {x.weekDay.toString().substring(0, 3)}
                    </Col>
                    <Col xl="8" style={{ textAlign: "left" }}>
                      {x.fromTime.toString().substring(0, 5)}-{x.toTime.toString().substring(0, 5)}
                    </Col>
                  </React.Fragment>
                   ))
                }
              </Row>
            }

          </Tooltip>
          
        }
      >
        <div ref={scheduleRef} className={styles["schedule"]}>{value && value.length > 0 ? value.map((x: any) => `${x.weekDay.toString().substring(0, 3)}, ${x.fromTime.toString().substring(0, 5)}-${x.toTime.toString().substring(0, 5)} `).toString() : null}</div>

      </OverlayTrigger>
    )
}

